.gw-card-item {
    border-radius: 12px;
    padding: 24px 24px 12px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);

    [class~=gw-card-header] {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;

        &[class~=gw-float] {
            position: absolute;
            top: 0;
            z-index: 1;
            [class~=gw-card-header-right] {
                margin-left: auto;
                margin-top: 20px;
                margin-right: 12px;
                [class~=gw-btn-menu] {
                    background-color: #ffffff;
                    &:hover {
                        background-color: #fbeceb;
                    }
                }
            }
        }

        [class~=gw-badge-verified] {
            width: 56px;
            height: 19px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: var(--gw-brand-color-red-1);
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
        }

        [class~=gw-card-header-left] {
            display: flex;
            align-items: flex-start;
            gap: 4px;

            [class~=gw-card-image-profile] {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
            }

            [class~=gw-card-user-info] {
                display: flex;
                flex-direction: column;
                gap: 2;

                [class~=gw-card-username] {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    font-size: 16px;
                    font-weight: 500;
                    color: var(--gw-brand-color-black-1);
                }

                [class~=gw-card-location] {
                    gap: 2px;

                    span {
                        color: var(--gw-brand-color-black-1);
                        font-size: 12px;
                        font-weight: 400;
                        opacity: .64;
                    }

                    [class~=ant-breadcrumb-separator] {
                        margin: 0px 2px;
                    }

                    [class~=gw-dot] {
                        width: 4px;
                        height: 4px;
                        margin-bottom: 2px;

                        circle {
                            fill: var(--gw-brand-color-gray-1);
                        }
                    }
                }
            }
        }

        [class~=gw-card-header-right] {
            display: flex;
            align-items: center;
            gap: 9px;

            [class~=gw-badge-verified] {
                height: 24px;
                background-color: var(--gw-brand-color-orenge-1);
            }

            [class~=gw-btn-menu] {
                padding: 4px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #00000000;
                border-radius: 50%;
                border-color: #00000000;

                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }

                &:hover {
                    background-color: #dd15080d;
                }
            }

            [class~=gw-btn-favourite] {
                --color-btn: #CED4D9;
                padding: 0;
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(227, 234, 239, 0.4);
                border-radius: 4px;

                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }

                &:hover {
                    [fill="#CED4D9"] {
                        fill: var(--color-btn);
                        stroke: unset;
                    }
                }
                
                &[class~=active] {
                    --color-btn: var(--gw-brand-color-red-1);
                    color: var(--color-btn);
                    border-color: var(--color-btn);
                    background-color: transparent;
                    
                    [fill="#CED4D9"] {
                        fill: var(--color-btn);
                        stroke: unset;
                    }
                }
            }
        }
    }

    [class~=gw-card-text-content] {
        margin-top: 16px;
        margin-bottom: 8px;

        span {
            font-size: 14px;
            font-weight: 300;
            color: var(--gw-brand-color-black-1);
            word-break: break-word;
            
            &[class~=title] {
                font-size: 16px;
            }
        }

        [class~=ant-btn-link] {
            padding: 0;
            height: 22px;

            span {
                color: var(--gw-brand-color-red-1);
                text-decoration: underline;
            }
        }
    }

    [class~=gw-card-list-image] {
        [class~=ant-image-mask] {
            opacity: 1;
        }

        [class~=gw-preview-mask] {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;

            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: var(--gw-brand-color-white-1);
            }
        }

        [class~=ant-image] {
            cursor: pointer;
            border-radius: 8px;
            overflow: hidden;
        }
    }

    [class~=gw-card-list-image][class~=type-1] {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 10.5px;

        [class~=row-1],
        [class~=row-2] {
            display: flex;
            gap: 16px;

            [class~=ant-image] {
                width: 100%;
                border-radius: 8px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        [class~=row-1] {
            [class~=ant-image] {
                height: 211px;
            }
        }

        [class~=row-2] {
            [class~=ant-image] {
                height: 136px;
            }
        }
    }

    [class~=gw-card-list-image][class~=type-2] {
        display: flex;
        gap: 10.5px;
        [class~=col-1] {
            display: flex;
            gap: 10.5px;
            flex-direction: column;
            width: 33.33%;
            [class~=ant-image] {
                width: 100%;
                height: 100%;
                [class~=ant-image-img] {
                    width: 100%;
                    height: 162px;
                    vertical-align: middle;
                    object-fit: cover;
                }
            }
        }
        [class~=col-2] {
            width: 66.67%;
            [class~=ant-image] {
                width: 100%;
                height: 100%;
                [class~=ant-image-img] {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    object-fit: cover;
                }
            }
        }
    }

    [class~=gw-card-carousel-images] {
        position: relative;
        margin-bottom: 20px;
        margin-left: -24px;
        width: calc(100% + 48px);

        [class~=ant-carousel] {
            [class~=slick-list] {
                height: calc(100% / (4/3));
                border-radius: 0px;
                overflow: hidden;
            }

            [class~=gw-carousel-item-image] {
                cursor: pointer;
            }

            [class~=ant-image] {
                width: 100%;
            }

            [class~=ant-image-img] {
                height: calc(100% / (4/3));
                width: 100%;
                object-fit: cover;
                border-radius: 0px;
                overflow: hidden;
            }

            [class~=slick-dots] {
                &[class~=slick-dots-bottom] {
                    bottom: -25px;
                    z-index: 0;
                }

                li {
                    margin: 0 5px;
                    height: 4px;
                    width: 4px;

                    button {
                        border-radius: 6px;
                        background: var(--gw-brand-color-red-1);
                        height: 6px;
                        width: 6px;
                    }

                    &[class~=slick-active] {
                        width: 12px;

                        button {
                            width: 17px;
                        }
                    }
                }
            }
        }
        [class~=arrow-prev],
        [class~=arrow-next] {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            color: white;
            font-size: 24px;
            cursor: pointer;
            border: 1px solid transparent;
            transition: all 0.15s ease-in-out;
            opacity: 0;
            &[class~=arrow-prev] {
                
            }
            &[class~=arrow-next] {
                right: 0;
            }
        }
        &:hover {
            [class~=arrow-prev],
            [class~=arrow-next] {
                opacity: 0.5;
                &:hover {
                    opacity: 1;
                    border: 1px solid #dd15085c;
                    background-color: #8300001e;
                }
            }
            

            // .gw-card-tag {
            //     .gw-card-tag-item {
            //         background-color: #f47f2073;
            //     }
            // }
        }
    }

    [class~=gw-card-details] {
        --color-gray: #3b3b3bb3;
        margin-top: 14.5px;
        display: flex;
        justify-content: space-between;

        [class~=gw-card-detail-left] {
            color: var(--color-gray);
            display: flex;
            flex-direction: column;

            [class~=price] {
                line-height: 24px;
                font-size: 18px;
                font-weight: 500;

                span {
                    color: var(--color-gray);
                }

                >span {
                    display: flex;
                    gap: 2px;
                }
            }

            [class~=area] {
                line-height: 18px;
                font-size: 14px;
                font-weight: 400;
            }
        }

        [class~=gw-card-detail-right] {
            display: flex;
            flex-direction: column;
            gap: 3px;

            [class~=text-icon] {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                display: flex;
                gap: 2px;
                align-items: center;
            }

            [class~=text-icon] span {
                color: var(--color-gray);
            }

            [class~=text-icon] svg {
                path {
                    fill: var(--color-gray);
                }
            }

            [class~=layer-1] {
                display: flex;
                gap: 16px;
            }

            [class~=layer-2] {
                display: flex;
            }
        }
    }

    [class~=gw-card-footer] {
        margin-top: 21px;
        display: flex;
        justify-content: space-between;

        [class~=gw-card-footer-left] {
            display: flex;
            gap: 16px;

            [class~=btn-coin] {
                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }

                img {
                    width: 26px;
                    height: 26px;
                }

                svg * {
                    filter: none;
                    -webkit-filter: grayscale(1);
                }

                path[fill="#FED843"],
                path[fill="#FED843"]+path[fill="#FABE2C"] {
                    fill: #ffffff;
                }

                &[class~=active] {
                    svg * {
                        filter: none;
                        -webkit-filter: grayscale(0);
                    }

                    path[fill="#FED843"] {
                        fill: #FED843;
                    }

                    path[fill="#FED843"]+path[fill="#FABE2C"] {
                        fill: #FABE2C;
                    }
                }

                &:hover,
                &:focus {
                    svg * {
                        filter: none;
                        -webkit-filter: grayscale(0.45);
                    }

                    path[fill="#FED843"] {
                        fill: #FED843;
                    }

                    path[fill="#FED843"]+path[fill="#FABE2C"] {
                        fill: #FABE2C;
                    }
                }
            }

            [class~=btn-chat] {
                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }

                &:hover {
                    [fill="#333333"] {
                        fill: #2196f3;
                    }
                }
            }

            [class~=btn-share] {
                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }
                &[class~=ant-tooltip-open],
                &:hover {
                    [fill="#333333"] {
                        fill: var(--gw-brand-color-red-1);
                    }
                }
            }
        }

        [class~=gw-card-footer-right] {
            display: flex;
            gap: 16px;

            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: var(--gw-brand-color-black-2);
                opacity: 0.64;
            }
        }
    }

    .gw-divider {
        margin: 18.96px 0px 8px;
    }

    .gw-card-boot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 6px;

        .gw-left {
            display: flex;
            align-items: center;
            gap: 16px;

            >span {
                display: flex;
                align-items: center;
                gap: 2px;

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--gw-brand-color-black-1);
                }
            }
        }

        .gw-right {
            button {
                border: 0;
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                border-radius: 8px;
                background-color: var(--gw-brand-color-orenge-1);
                color: #ffffff;
            }
        }
    }

    &.gw-card-item-broadcast {
        padding: 24px 0px 12px;

        [class~=gw-card-details] {
            margin-top: 10px;

            [class~=gw-card-detail-left] {
                [class~=price] {
                    span:not(.gw-prefix-unit) {
                        color: var(--gw-brand-color-black-1);
                    }
                }
            }
        }

        [class~=gw-card-footer] {
            [class~=gw-card-footer-left] {
                [class~=btn-chat] {
                    [fill="#333333"] {
                        fill: var(--gw-brand-color-red-1);
                    }
                }

                [class~=btn-share] {
                    [fill="#333333"] {
                        fill: var(--gw-brand-color-red-1);
                    }
                }
            }
        }

        >div:not([class~=gw-map]) {
            padding: 0px 24px;
        }
    
        [class~=gw-map] {
            margin-top: 10px;
            border-radius: 8px;
            overflow: hidden;
        }
    
        >div[class~=gw-broad-cast] {
            padding: 0px 0px;
        }
    }

    &.gw-card-item-pin-location {
        padding: 0px 0px 27px;
        [class~=gw-card-footer] {
            [class~=gw-card-footer-left] {
                align-items: flex-start;
            }
            [class~=gw-card-footer-pin] {
                display: flex;
                flex-direction: column;
                gap: 11px;
                [class~=gw-text-detail] {
                    display: flex;
                    gap: 5px;
                    [class~=gw-icon] {
                        width: 20px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    [class~=gw-text] {
                        display: flex;
                        align-items: center;
                        span {
                            font-family: 'Sarabun';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}

.gw-share {
    display: flex;
    gap: 8px;
    svg {
        width: 24px;
        height: 24px;
    }
}

[class~=gw-modal-sale] {
    [class~=ant-modal-close-x] {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -30px;
    }
    [class~=ant-modal-content] {
        border-radius: 12px;
    }
    .gw-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: var(--gw-brand-color-red-1);
        margin-bottom: 8px;
    }
    .gw-block-detail {
        display: flex;
        gap: 16px;
        margin-bottom: 24px;
        padding-top: 8px;
    }
    img[class~=ant-image-img] {
        width: 109px;
        height: 83px;
        object-fit: cover;
        border-radius: 1.81395px;
        overflow: hidden;
    }
    [class~=gw-detail] {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-top: 8px;
        height: 52px;
    }
    [class~=ant-modal-footer], [class~=ant-modal-confirm-btns] {
        text-align: center;
        button {
            height: 36px;
            width: 130px;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 400;

            &[class~=ant-btn-default] {
                background: var(--gw-brand-color-white-1);
                border: 1px solid var(--gw-brand-color-gray-2);
                color: var(--gw-brand-color-black-1);
            }
        
            &[class~=ant-btn-primary] {
                border: 0;
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                background-color: var(--gw-brand-color-red-1);
                color: #ffffff;
            }
        }
    }
    .gw-block-container {
        width: 100%;
        --gw-custom-height: 48px;
        [class~=ant-row] {
            margin-top: 8px;
        }
        .gw-form-selector {
            [class~=ant-select-selection-placeholder] {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: var(--gw-brand-color-black-1);
                opacity: 0.80;
            }

            [class~=ant-select-arrow] {
                top: 40%;
                right: 17px;
            }

            [class~=ant-select] {
                [class~=ant-select-selector] {
                    border-radius: 8px;
                    border-color: var(--gw-brand-color-gray-1);
                    height: var(--gw-custom-height);
                    display: flex;
                    align-items: center;

                    [class~=ant-select-selection-search-input] {
                        height: calc(var(--gw-custom-height) - 2px);

                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: var(--gw-brand-color-black-1);
                    }
                }

                &:not([class~=ant-select-disabled]) {

                    &:hover,
                    &:focus,
                    &[class~=ant-select-focused] {
                        [class~=ant-select-selector] {
                            border-color: var(--gw-brand-color-red-1);
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        .gw-form-input {
            [class~=ant-input-group-compact] {
                display: flex;

                [class~=ant-input-number] {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                button {
                    border-radius: 8px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    font-size: 18px;
                    font-weight: 400;
                    height: calc(var(--gw-custom-height));
                    border: 0;
                    background-color: var(--gw-brand-color-red-1);
                    color: #ffffff;
                    padding: 7px;
                }
            }

            [class~=ant-form-item-control-input-content]>[class~=ant-input],
            [class~=ant-input-affix-wrapper],
            [class~=ant-input-number],
            [class~=ant-input-number-affix-wrapper] {
                width: 100%;
                height: var(--gw-custom-height);
                border-radius: 8px;
                overflow: hidden;
                border-color: var(--gw-brand-color-gray-1);
                box-shadow: none;

                [class~=ant-input-number-input] {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    height: calc(var(--gw-custom-height) - 2px);

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.80;
                        /* Firefox */
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.80;
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.80;
                    }
                }

                &:not([disabled]),
                &:not([class~=ant-input-number-affix-wrapper-disabled]),
                &:not([class~=ant-input-affix-wrapper-disabled]) {

                    &:hover,
                    &:focus,
                    &[class~=ant-input-number-affix-wrapper-focused],
                    &[class~=ant-input-affix-wrapper-focused] {
                        border-color: var(--gw-brand-color-red-1);
                    }
                }

                [class~=ant-input-number-prefix] {
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                [class~=ant-input-suffix] {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--gw-brand-color-red-1);
                }
            }

            &[class~=ant-form-item-has-error] {

                [class~=ant-form-item-control-input-content]>[class~=ant-input],
                [class~=ant-input-affix-wrapper],
                [class~=ant-input-number-affix-wrapper] {
                    border-color: #ff4d4f;

                    &:not([disabled]),
                    &:not([class~=ant-input-number-affix-wrapper-disabled]),
                    &:not([class~=ant-input-affix-wrapper-disabled]) {

                        &:hover,
                        &:focus,
                        &[class~=ant-input-number-affix-wrapper-focused],
                        &[class~=ant-input-affix-wrapper-focused] {
                            border-color: #ff4d4f;
                        }
                    }
                }
            }
        }
    }
    [class~=ant-form-item] {
        margin: 0;
    }
    [class~=ant-modal-footer] {
        padding: 15px 16px;
    }
}

.gw-card-tag {
    position: absolute;
    bottom: 16px;
    left: 24px;
    z-index: 1;
    display: flex;
    gap: 5px;

    .gw-card-tag-item {
        transition: background-color .3s ease-in-out;
        color: #fff;
        border-radius: 6px;
        padding: 0px 10px;

        span {
            line-height: 20px;
            font-size: 12px;
        }
        
        &.gw-type {
            background-color: #E93F40B3;
            &[type="rent"] {
                background-color: #EF650CB3;
            }
        }
        &.gw-category {
            &[category="1"] {
                background-color: rgba(16, 115, 110, 0.70);
                background-color: #7FC379B3;
            }
            &[category="2"] {
                background-color: rgba(252, 217, 40, 0.70);
                background-color: #FADC2AB3;
            }
            &[category="3"] {
                background-color: rgba(113, 200, 235, 0.70);
                background-color: #9FDCF3B3;
            }
            &[category="4"] {
                background-color: rgba(89, 89, 92, 0.70);
                background-color: #9D9D9FB3;
            }
            &[category="5"] {
                background-color: rgba(116, 57, 53, 0.70);
                background-color: #936941B3;
            }
            &[category="6"] {
                background-color: rgba(241, 88, 226, 0.70);
                background-color: #FF68D5B3;
            }
            &[category="7"] {
                background-color: rgba(113, 200, 235, 0.70);
                background-color: #9FDCF3B3;
            }
        }

        &[is-broadcast="true"] {
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
}