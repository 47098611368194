.gw-header {
    background-color: white;
    height: 80px;
    display: flex;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 0px 0px 12px 12px;
    z-index: 10;
    position: fixed;
    top: 0;
    width: 100%;

    [class~=gw-container] {
        display: flex;
        align-items: center;

        position: relative;
    }

    @media screen and (max-width: 767px) {
        height: 65px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }
}

.gw-block-blank {
    height: 80px;
}

.gw-dropdown-quickmenu,
.gw-dropdown-searchmenu,
.gw-dropdown-notificaion,
.gw-dropdown-messenger {
    width: 100%;
    max-width: 435px;
    top: 88px !important;

    @media screen and (max-width: 767px) {
        top: 70px !important;
    }
}

.gw-dropdown-notificaion,
.gw-dropdown-messenger {
    left: unset !important;
    right: 0px;
}

.gw-dropdown-quickmenu {
    [class~=ant-dropdown-menu] {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), 0px 20px 40px -4px rgba(145, 158, 171, 0.24);
        border-radius: 8px;
        padding: 16px;
        [class~=ant-dropdown-menu-item-group-title] {
            padding: 0;
            
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: var(--gw-brand-color-black-1);
        }

        [class~=ant-dropdown-menu-item-group-list] {
            margin: 0;
            margin-top: 8px;

            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            max-height: 82px;
            overflow-y: hidden;
        }

        [class~=ant-dropdown-menu-item] {
            background-color: var(--gw-brand-color-white-1);
            border: 1px solid var(--gw-brand-color-gray-1);
            border-radius: 6px;
            height: 22px;
            padding: 0px 12px;
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: var(--gw-brand-color-black-1);

                max-width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            &:hover {
                border-color: var(--gw-brand-color-red-1);
            }
        }
    }
}

.gw-dropdown-searchmenu {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), 0px 20px 40px -4px rgba(145, 158, 171, 0.24);

    [class~=ant-dropdown-menu] {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), 0px 20px 40px -4px rgba(145, 158, 171, 0.24);
        border-radius: 8px;
        padding: 16px;
    }
    
    [class~=gw-menu-toggle] {
        max-height: 500px;
        overflow: auto;
    }

    [class~=ant-dropdown-menu-item],
    [class~=ant-dropdown-menu-submenu-title] {
        height: 45px;

        [class~=ant-dropdown-menu-title-content] {
            width: 100%;
            max-width: 100%;

            &>span {
                gap: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                [class~=text] {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                [class~=type] {
                    color: #747474;
                    font-size: 12px;
                }
            }
        }
    }
}
.gw-block-header-menu {
    > [class~=ant-dropdown-menu-item-group-list] {
        max-height: 550px;
        overflow: auto;

        @media screen and (max-width: 767px) {
            max-height: 350px;
        }
    }
    .gw-header-menu {
        padding: 20.5px 36px 20.5px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .gw-left {
            display: flex;
            flex-direction: column;
    
            span.gw-text-title {
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: var(--gw-brand-color-red-1);
            }
    
            span.gw-text-descrioption {
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                color: var(--gw-brand-color-black-1);
            }
        }
        .gw-right {
            
        }
    }
    .gw-search-header-menu {

    }
    [class~=ant-spin-spinning] {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
 }

.gw-block-footer-menu {
    .gw-footer-menu {
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: var(--gw-brand-color-red-1);
        }
    }
}

.gw-dropdown-notificaion,
.gw-dropdown-messenger {
    [class~=ant-dropdown-menu] {
        padding: 0;
        border-radius: 8px;
        box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.24);
        overflow: hidden;
    }
    [class~=ant-dropdown-menu-item-group-title] {
        padding: 0px;
    }
    [class~=ant-dropdown-menu-item-group-list] {
        margin: 0;
    }
    [class~=ant-dropdown-menu-item-divider] {
        margin: 0;
        background-color: var(--gw-brand-color-gray-1);
    }
}

.gw-dropdown-notificaion {
    .gw-sub-header-menu {
        height: 34px;
        padding: 0px 24px;
        display: flex;
        align-items: center;
        .gw-text-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: var(--gw-brand-color-black-1);
        }
    }
    .gw-menu-item {
        padding: 12px 24px;
        & ~ .gw-menu-item {
            margin-top: 1px;
        }
        &.gw-read {
            background-color: var(--gw-brand-color-orenge-1);
            &:hover {
                background-color: #f48020a8;
            }
        }
        [class~=ant-dropdown-menu-title-content] {
            display: flex;
            gap: 12px;
            .gw-menu-item-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 44px;
                height: 44px;
                min-width: 44px;
                min-height: 44px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                > svg {
                    width: 24px;
                    height: 24px;
                }
                > [class~=ant-image] {
                    border-radius: 50%;
                    overflow: hidden;
                    height: 100%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .gw-menu-item-description {
                display: flex;
                flex-direction: column;
                gap: 4px;
                span.gw-text-description {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                }
                span.gw-text-description {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 22px;
                    color: var(--gw-brand-color-black-1);
                }
                .gw-menu-item-time {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    > svg {
                        width: 16px;
                        height: 16px;
                    }
                    span.gw-date-time {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: var(--gw-brand-color-black-1);
                    }
                    span.gw-dash {
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 12px;
                        color: var(--gw-brand-color-gray-1);
                    }
                    span.gw-date-location {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 13px;
                        color: var(--gw-brand-color-red-1);
                    }
                }
            }
        }
    }
}

.gw-dropdown-messenger {
    
    .gw-sub-header-menu {
        padding: 8px 24px;
        display: flex;
        align-items: center;
        [class~=ant-input-affix-wrapper] {
            padding: 8px;
            height: 100%;
            border-radius: 8px;
            border: 1px solid #CED4D9;
            box-shadow: none;
            &[class~=ant-input-affix-wrapper-focused], 
            &[class~=ant-input-affix-wrapper]:focus {
                border-color: var(--gw-brand-color-red-1);
            }
            input {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: var(--gw-brand-color-black-1);
                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: var(--gw-brand-color-gray-1);
                    opacity: 1;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: var(--gw-brand-color-gray-1);
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: var(--gw-brand-color-gray-1);
                }
            }
        }
    }
    .gw-menu-item {
        padding: 12px 24px;
        & ~ .gw-menu-item {
            margin-top: 1px;
        }
        [class~=ant-dropdown-menu-title-content] {
            display: flex;
            gap: 12px;
            .gw-menu-item-img {
                [class~=ant-image-img] {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                }
            }
            .gw-menu-item-description {
                display: flex;
                gap: 12px;
                justify-content: space-between;
                width: 100%;
                .gw-user-info {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    span {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }
                    span.gw-text-username {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                    }
                    span.gw-text-message {
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                .gw-user-notification {
                    height: 49px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: end;
                    max-width: 75px;
                    width: 100%;
                    .gw-badge {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: var(--gw-brand-color-other-1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span {
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 12px;
                            color: #ffffff;
                            margin-right: 1px;
                        }
                    }
                    .gw-date-time {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 13px;
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.64;
                    }
                }
            }
        }
    }
}

.gw-logo {
    max-width: 187px;
    max-height: 35px;
    display: flex;
    align-items: center;
    img {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        img {
            max-height: unset;
            max-width: unset;
            width: 131px;
            height: 14px;
            object-fit: contain;
            object-position: left;
        }
    }
}

.gw-input-search {
    margin-left: 35px;
    width: 303px;
    height: 35px;
    input,
    button {
        height: 35px;
        border: 1px solid #CED4D9 !important;
        box-shadow: none !important;
    }
    &:hover {
        input {
            border-color: var(--gw-brand-color-red-1) !important;
        }
        button {
            background-color: var(--gw-brand-color-red-1);
            border-color: var(--gw-brand-color-red-1) !important;
        }
    }
    input {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: 0 !important;
        font-size: 16px;
        &:focus {
            border-color: var(--gw-brand-color-red-1) !important; 
            & ~ span button {
                background-color: var(--gw-brand-color-red-1);
                border-color: var(--gw-brand-color-red-1) !important;
            }
        }
    }
    
    button {
        z-index: 1;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        transition: all .3s;
        border-left: 0 !important;
        background-color: var(--gw-brand-color-red-1) !important;
        color: var(--gw-brand-color-white-1) !important;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
    }
}

.gw-list-btn-link {
    display: flex;
    gap: 40px;
    margin-left: auto;
    .gw-btn-link {
        color: var(--gw-brand-color-gray-1);
        font-weight: 400;
        .gw-link-text {
            display: flex;
            align-items: center;
            gap: 4px;
            white-space: nowrap;
        }
        &.active {
            color: var(--gw-brand-color-red-1);
            font-weight: 700;
            path[fill="#CED4D9"] {
                fill: var(--gw-brand-color-red-1);
            }
            path[stroke="#CED4D9"],
            ellipse[stroke="#CED4D9"],
            rect[stroke="#CED4D9"] {
                stroke: var(--gw-brand-color-red-1);
            }
        }
    }
}

.gw-btn-action {
    display: flex;
    gap: 24px;
    margin-left: 24px;
    .gw-btn-login {
        background-color: var(--gw-brand-color-red-1);
        color: var(--gw-brand-color-white-1) !important;
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        border-radius: 8px;
        border: 1px solid var(--gw-brand-color-red-1);
        &:hover,
        &:focus {
            opacity: 0.85;
        }
    }
    &.is-login {
        display: flex;
        gap: 24px;
        .gw-btn-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: #ffffff;
            box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
            svg * {
                transition: all .3s cubic-bezier(.645, .045, .355, 1);
            }
            &:hover,
            &:focus {
                border-color: var(--gw-brand-color-red-1);
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
            }
            &.active {
                border-color: var(--gw-brand-color-red-1);
                background-color: var(--gw-brand-color-red-1);
                path {
                    &[stroke="#333333"] {
                        stroke: var(--gw-brand-color-white-1);
                    }
                    &[fill="#333333"] {
                        fill: var(--gw-brand-color-white-1);
                    }
                }
                &:hover,
                &:focus {
                    path[stroke="#333333"] {
                        stroke: var(--gw-brand-color-gray-2);
                    }
                }
            }
        }
        [class~=ant-dropdown-open] {
            .gw-btn-circle {
                border-color: var(--gw-brand-color-red-1);
                background-color: var(--gw-brand-color-red-1);
                path {
                    &[stroke="#333333"] {
                        stroke: var(--gw-brand-color-white-1);
                    }
                    &[fill="#333333"] {
                        fill: var(--gw-brand-color-white-1);
                    }
                }
                circle,
                ellipse {
                    &[fill="#333333"] {
                        fill: var(--gw-brand-color-white-1);
                    }
                }
            }
        }
    }
    [class~=ant-badge-count] {
        background-color: var(--gw-brand-color-other-1);
        min-width: 0;
        width: 7px;
        height: 7px;
        padding: 0;
        box-shadow: none;
        top: 4px;
        right: 4px;
        [class~=ant-scroll-number-only-unit] {
            display: none;
        }
    }
}

.gw-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 50;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    height: 65px;
    background-color: white;
    color: var(--color-grey, #CED4D9);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    [class~=gw-footer-container] {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        padding: 0 21px;
        padding-bottom: 15px;

        [class~=gw-footer-menu] {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                height: 20px;
                width: 20px;
            }

            span {
                white-space: nowrap;
            }
        }
    }
}

.gw-header-search-mobile {
    // position: fixed;
    // top: 100px;
    // z-index: 1;
}