.gw-side-menu {
    .gw-header {
        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: var(--gw-brand-color-red-1);
        }
    }

    .gw-list-menu {
        margin-top: 45px;

        [class~=ant-btn] {
            margin-bottom: 16px;
            width: 100%;
            height: 80px;
            border: none;
            padding: 24px;
            border-radius: 12px;
            box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
            background-color: #EFF0F0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            span {
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;

                color: var(--gw-brand-color-black-1);
                opacity: 0.72;
            }
            &[disabled] {
                cursor: default;
                background-color: #ffffff;
                span {
                    color: var(--gw-brand-color-red-1);
                    opacity: 1;
                }
            }
            &:not([disabled]):focus,
            &:not([disabled]):hover {
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.60);
            }
            .gw-btn-icon {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                svg {
                    [fill="#DD1508"] {
                        fill: #333333;
                        fill-opacity: 0.72;
                    }
                }
            }
            &[disabled] {
                .gw-btn-icon {
                    
                    border-radius: 50%;
                    box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                    svg {
                        [fill="#333333"] {
                            fill: #DD1508;
                            fill-opacity: 0.72;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            margin-top: 0px;
        }
    }
}

.gw-feed-content {
    .gw-feed-text-header {
        position: relative;
        width: 100%;
        height: 196px;
        background-color: var(--gw-brand-color-gray-2);
        border-radius: 8px;
        overflow: hidden;
        img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;

            @media screen and (max-width: 767px) {
                height: 100%;
                object-fit: cover;
            }
        }
        span {
            position: absolute;
            top: 59px;
            left: 24px;

            font-weight: 500;
            font-size: 40px;
            line-height: 62px;
            color: #ffffff;
        }

        @media screen and (max-width: 767px) {
            height: 85px;
            span {
                top: 50%;
                transform: translateY(-50%);
                
                font-size: 30px;
                line-height: 42px;
            }
        }
    }
    .gw-feed-list {
        margin-top: 27px;
        padding-bottom: 24px;
    }
}

.gw-mobile-navigation {
    position: fixed;
    top: 70px;
    right: 0px;
    z-index: 10;
    background-color: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}