.navigate-container {
    position: relative;
    min-height: calc(100vh - 80px - 32px);
    .btn-navigate-action {
        position: fixed;
        z-index: 2;
        right: 16px;
        bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: var(--gw-brand-color-red-1);
            color: white;
            width: 64px;
            height: 64px;
            box-shadow: 0px 8px 16px rgba(28, 143, 108, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            img {
                height: 24px;
            }
            &:hover,
            &:focus {
                box-shadow: 0px 19px 37px rgba(28, 143, 108, 0.35);
            }
        }
        
        @media screen and (max-width: 767px) {
            bottom: 80px;
        }
    }
}

.gw-header {
    display: flex;
}

[class~=gw-item-image] {
    border-radius: 8px;
    overflow: hidden;
    img {
        object-fit: cover;
    }
    [class~=gw-preview-mask] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: var(--gw-brand-color-white-1);
        }
    }
}
[class~=gw-count-images] {
    margin: 8.25px 0px 32px;
    display: flex;
    justify-content: center;
    gap: 4px;
    span {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: var(--gw-brand-color-black-1);
    }
}