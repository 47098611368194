.gw-header {
    display: flex;
    align-items: center;
    gap: 8px;
    .gw-text-prefix {
        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;

            color: var(--gw-brand-color-red-1);
        }
    }
}
.gw-chat-header-search {
    display: flex;
    align-items: center;

    [class~=ant-input-affix-wrapper] {
        padding: 0px 8px;
        height: 40px;
        width: 344px;
        border-radius: 8px;
        border: 1px solid #CED4D9;
        box-shadow: none;

        &[class~=ant-input-affix-wrapper-focused],
        &[class~=ant-input-affix-wrapper]:focus {
            border-color: var(--gw-brand-color-red-1);
        }

        input {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: var(--gw-brand-color-black-1);

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: var(--gw-brand-color-gray-1);
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: var(--gw-brand-color-gray-1);
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: var(--gw-brand-color-gray-1);
            }
        }
    }
}

.gw-card-tag {
    position: absolute;
    bottom: 16px;
    left: 24px;
    z-index: 1;
    display: flex;
    gap: 5px;

    .gw-card-tag-item {
        transition: background-color .3s ease-in-out;
        color: #fff;
        border-radius: 6px;
        padding: 0px 10px;

        span {
            line-height: 20px;
            font-size: 12px;
            color: #fff;
        }
        
        &.gw-type {
            background-color: #E93F40B3;
            &[type="rent"] {
                background-color: #EF650CB3;
            }
        }
        &.gw-category {
            &[category="1"] {
                background-color: rgba(16, 115, 110, 0.70);
                background-color: #7FC379B3;
            }
            &[category="2"] {
                background-color: rgba(252, 217, 40, 0.70);
                background-color: #FADC2AB3;
            }
            &[category="3"] {
                background-color: rgba(113, 200, 235, 0.70);
                background-color: #9FDCF3B3;
            }
            &[category="4"] {
                background-color: rgba(89, 89, 92, 0.70);
                background-color: #9D9D9FB3;
            }
            &[category="5"] {
                background-color: rgba(116, 57, 53, 0.70);
                background-color: #936941B3;
            }
            &[category="6"] {
                background-color: rgba(241, 88, 226, 0.70);
                background-color: #FF68D5B3;
            }
            &[category="7"] {
                background-color: rgba(113, 200, 235, 0.70);
                background-color: #9FDCF3B3;
            }
        }

        &[is-broadcast="true"] {
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
}