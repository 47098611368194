
@media screen and (max-width: 767px) {
    [class~=ant-drawer-body] {
        .gw-chat {
            margin-top: 0px;
        }
    }
}
.gw-chat {
    @media screen and (max-width: 767px) {
        margin-top: 40px;
    }
    [class~=ant-col] {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }

    .gw-chat-header {
        .gw-chat-header-label {
            font-weight: 500;
            font-size: 24px;
            line-height: 31px;
            color: var(--gw-brand-color-red-1);
        }

        .gw-chat-header-search {
            display: flex;
            align-items: center;

            [class~=ant-input-affix-wrapper] {
                padding: 8px;
                height: 100%;
                border-radius: 8px;
                border: 1px solid #CED4D9;
                box-shadow: none;

                &[class~=ant-input-affix-wrapper-focused],
                &[class~=ant-input-affix-wrapper]:focus {
                    border-color: var(--gw-brand-color-red-1);
                }

                input {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--gw-brand-color-black-1);

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: var(--gw-brand-color-gray-1);
                        opacity: 1;
                        /* Firefox */
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: var(--gw-brand-color-gray-1);
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: var(--gw-brand-color-gray-1);
                    }
                }
            }
        }
    }

    .gw-chat-list {
        .gw-chat-user-container {
            position: relative;
            [class~=gw-dropdown-menu] {
                position: absolute;
                right: 9px;
                top: 4px;
            }
            button {
                border: 0;
                color: var(--gw-brand-color-red-1);
                border-radius: 50%;
                padding: 4px;
                width: 30px;
                height: 30px;
                svg {
                    width: 17px;
                    height: 10px;
                }
                &:hover {
                    background-color: #dd15080d;
                }
            }
        }

        .gw-menu-item {
            display: flex;
            gap: 12px;
            background-color: #ffffff;
            box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
            border-radius: 12px;
            padding: 14px 24px 14px 12px;

            &.gw-active {
                background-color: var(--gw-brand-color-red-1);

                .gw-menu-item-description {
                    .gw-user-info {
                        span.gw-text-username {
                            color: var(--gw-brand-color-white-1);
                        }

                        span.gw-text-message {
                            color: var(--gw-brand-color-white-1);
                        }
                    }

                    .gw-user-notification {
                        .gw-date-time {
                            color: var(--gw-brand-color-white-1);
                        }
                    }
                }
            }

            .gw-menu-item-img {
                [class~=ant-image-img] {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                }
            }

            .gw-menu-item-description {
                display: flex;
                gap: 16px;
                width: 100%;
                justify-content: space-between;

                .gw-user-info {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    span {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }

                    span.gw-text-username {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 23px;
                        color: var(--gw-brand-color-black-1);
                    }

                    span.gw-text-message {
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 18px;
                        color: var(--gw-brand-color-black-2);
                    }
                }

                .gw-user-notification {
                    height: 49px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: end;
                    max-width: 75px;
                    width: 100%;

                    .gw-badge {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: var(--gw-brand-color-other-1);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 12px;
                            color: #ffffff;
                            margin-right: 1px;
                        }
                    }

                    .gw-date-time {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.64;
                    }
                }
            }
        }
    }

    .gw-chat-block {
        margin-top: 88px;
        background-color: #ffffff;
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        border-radius: 12px;
        padding: 24px 26px;

        [class~=ant-divider][class~=ant-divider-horizontal] {
            border-color: var(--gw-brand-color-gray-2);
        }

        .gw-chat-user-info {
            display: flex;
            align-items: center;
            gap: 16px;
            &.gw-btn-link {
                &:hover {
                    background-color: #ced4d969;
                    border-radius: 50px;
                    cursor: pointer;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                }
            }

            [class~=ant-image] {
                [class~=ant-image-img] {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    box-shadow: 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                }
            }

            .gw-chat-description {
                display: flex;
                flex-direction: column;

                span.gw-chat-username {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    color: var(--gw-brand-color-black-1);
                }

                span.gw-chat-time-active {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--gw-brand-color-black-2);
                }
            }
        }

        .gw-chat-content {
            background-color: var(--gw-brand-background-1);
            padding: 24px;
            border-radius: 12px;

            .gw-chat-history {
                height: 500px;
                overflow: auto;

                .gw-message-block {
                    display: flex;
                    align-items: flex-end;
                    gap: 12px;

                    .gw-message-user-info {
                        width: 30px;

                        [class~=gw-image-profile] {
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            object-fit: cover;
                        }
                    }

                    .gw-message-box {
                        margin-top: 5px;
                        display: flex;
                        gap: 4px;

                        .gw-message-block-content {
                            display: flex;
                            flex-direction: column;
                        }

                        .gw-message-image {
                            // min-width: 250px;
                            // min-height: 250px;
                            max-width: 250px;
                            max-height: 250px;
                            border-radius: 5px;
                            overflow: hidden;

                            img[class~=ant-image-img] {
                                max-width: 250px;
                                max-height: 250px;
                            }
                        }

                        .gw-message-content {
                            min-height: 30px;
                            background-color: #ffffff;
                            border: 1px solid var(--gw-brand-color-gray-1);
                            border-radius: 5px;
                            padding: 6px 12px;
                            max-width: 400px;
                            display: flex;

                            span {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                            }

                        }

                        .gw-message-state {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            min-width: 35px;

                            .gw-message-read,
                            .gw-message-date-time {
                                font-weight: 300;
                                font-size: 12px;
                                line-height: 16px;
                                color: var(--gw-brand-color-black-2);
                            }
                        }

                        &.gw-message-text-location {
                            .gw-message-block-content {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                gap: 16px;
                                padding: 8px;
                                background-color: white;
                                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                                border-radius: 12px;
                                max-width: 360px;

                                [class~=ant-image-img] {
                                    width: 114px;
                                    height: 76px;
                                    object-fit: cover;
                                    border-radius: 11.122px;

                                    @media screen and (max-width: 767px) {
                                        --card-size: 20px;
                                        width: calc(114px - var(--card-size));
                                        height: calc(76px - var(--card-size));
                                        border-radius: 7px;
                                    }
                                }

                                .gw-message-location-description {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 2px;

                                    span.gw-title {
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: #000000;

                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        word-break: break-all;
                                    }

                                    span.gw-price {
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 16px;

                                        span.gw-unit {
                                            color: var(--gw-brand-color-black-1);
                                        }

                                        span.gw-value {
                                            color: var(--gw-brand-color-black-1);
                                        }
                                    }

                                    [class~=gw-btn-link] {
                                        span {
                                            font-weight: 400;
                                            font-size: 12px;
                                            line-height: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.gw-message-self+.gw-message-not-self,
                    &.gw-message-not-self+.gw-message-self {
                        margin-top: 16px;
                    }

                    &.gw-message-self {
                        flex-direction: row-reverse;

                        .gw-message-box {
                            flex-direction: row-reverse;

                            &~.gw-message-box {
                                margin-top: 5px;
                            }

                            .gw-message-content {
                                justify-content: flex-end;
                                background-color: var(--gw-brand-color-red-1);
                                color: #ffffff;
                            }

                            .gw-message-state {
                                align-items: flex-end;
                            }
                        }
                    }
                }
                
                @media screen and (max-width: 767px) {
                    height: calc(500px - 60px);
                }
            }

            [class~=gw-comment-input] {
                margin-top: 32px;
                display: flex;
                align-items: flex-start;
                gap: 8px;
        
                [class~=gw-input-addons],
                [class~=gw-input-user-profile] {
                    margin-top: 2px;
                }
        
                &[class~=has-reply] {
                    align-items: flex-start;
        
                    [class~=gw-input-addons],
                    [class~=gw-input-user-profile] {
                        margin-top: 67px;
                    }
                }
        
                [class~=gw-input-addons] {
                    display: flex;
                    gap: 7px;
        
                    button {
                        padding: 0;
                        width: 32px;
                        height: 32px;
                        background: #FFFFFF;
                        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                        border-radius: 4px;
                        border: none;
        
                        display: flex;
                        justify-content: center;
                        align-items: center;
        
                        &:hover {
                            box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.50);
                        }
        
                        svg:not([class]) {
                            width: 16px;
                        }
        
                        position: relative;
        
                        svg[class~=gw-addsquare] {
                            position: absolute;
                            top: 7px;
                            right: 6px;
                        }
                    }
                }
        
                [class~=gw-input-user-profile] {
                    [class~=gw-image-profile] {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        object-fit: cover;
                        filter: drop-shadow(0px 0px 60px rgba(70, 13, 92, 0.1));
                    }
                }
        
                [class~=gw-input-box] {
                    width: 100%;
        
                    [class~=ant-input-affix-wrapper] {
                        width: 100%;
                        height: 35px;
                        border-radius: 114.362px;
                        background-color: var(--gw-brand-color-white-1);
                        border: none;
                        box-shadow: none;
                        border: 1px solid white;
                        padding: 0px 11px 0px 17px;
        
                        input {
                            background-color: transparent;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: var(--gw-brand-color-black-1);
                        }
        
                        &[class~=ant-input-affix-wrapper-focused],
                        &[class~=ant-input-affix-wrapper]:focus {
                            border: 1px solid var(--gw-brand-color-red-1);
                        }
        
                        [class~=ant-input-suffix] {
                            display: flex;
                            justify-content: center;
                            gap: 4px;
                        }
                    }
        
                    [class~=gw-reply-container] {
                        background-color: var(--gw-brand-color-white-1);
                        border-radius: 25px;
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
        
                        [class~=gw-user-info] {
                            display: flex;
                            gap: 8px;
        
                            [class~=gw-username] {
                                span {
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 21px;
                                    color: #000000;
                                }
                            }
        
                            [class~=gw-block-dot] {
                                [class~=gw-dot] {
                                    width: 4px;
                                    height: 4px;
                                    margin-bottom: 2px;
        
                                    circle {
                                        fill: var(--gw-brand-color-gray-1);
                                    }
                                }
                            }
        
                            [class~=gw-date] {
                                span {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    color: var(--gw-brand-color-black-2);
                                }
                            }
                        }
        
                        [class~=gw-reply-action] {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: flex-start;
                            gap: 0;
                            padding-left: 15px;
                            justify-content: center;
                            align-content: flex-start;
                            
                            [class~=gw-btn-link][class~=btn-coin] {
                                margin-top: 10px;
                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
        
                        [class~=gw-reply-close] {
                            cursor: pointer;
                            width: 20px;
                            height: 20px;
                            * {
                                transition: all .15s cubic-bezier(.645,.045,.355,1);
                            }
                            svg {
                                width: 100%;
                                height: 100%;
                                fill: #9c9c9c;
                            }
                            &:hover {
                                svg {
                                    fill: var(--gw-brand-color-red-1);
                                }
                            }
                        }
                        
                        [class~=gw-comment-preview-image] {
                            max-width: 200px;
                            max-height: 200px;
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                }
                
                .gw-message-text-location {
                    .gw-message-block-content {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
                        padding: 8px;
                        background-color: white;
                        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                        border-radius: 12px;
                        max-width: 360px;

                        [class~=ant-image-img] {
                            width: 114px;
                            height: 76px;
                            object-fit: cover;
                            border-radius: 11.122px;
                        }

                        .gw-message-location-description {
                            display: flex;
                            flex-direction: column;
                            gap: 2px;

                            span.gw-title {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 18px;
                                color: #000000;
                                
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-break: break-all;
                            }

                            span.gw-price {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;

                                span.gw-unit {
                                    color: var(--gw-brand-color-red-1);
                                }

                                span.gw-value {
                                    color: var(--gw-brand-color-black-1);
                                }
                            }

                            [class~=gw-btn-link] {
                                span {
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }
            }
                
            @media screen and (max-width: 767px) {
                padding: 12px;
            }
        }

        @media screen and (max-width: 767px) {
            margin-top: 20px;
            padding: 12px 12px;
        }
    }

    [class~=typing] {
        align-items: center;
        display: flex;
        height: 17px;
    }

    [class~=typing] [class~=dot] {
        animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
        background-color: var(--gw-brand-color-red-1); //rgba(20,105,69,.7);
        border-radius: 50%;
        height: 8px;
        width: 8px;
        margin-right: 4px;
        vertical-align: middle;
        display: inline-block;
    }

    [class~=typing] [class~=dot]:nth-child(1) {
        animation-delay: 200ms;
    }

    [class~=typing] [class~=dot]:nth-child(2) {
        animation-delay: 300ms;
    }

    [class~=typing] [class~=dot]:nth-child(3) {
        animation-delay: 400ms;
    }

    [class~=typing] [class~=dot]:last-child {
        margin-right: 0;
    }

    @keyframes mercuryTypingAnimation {
        0% {
            transform: translateY(0px);
            background-color: var(--gw-brand-color-red-1); // rgba(20,105,69,.7);
        }

        28% {
            transform: translateY(-7px);
            background-color: #dd160896; //rgba(20,105,69,.4);
        }

        44% {
            transform: translateY(0px);
            background-color: #dd160871; //rgba(20,105,69,.2);
        }
    }
}