button.btn-link {
    padding: 0;
    height: 32px;
    font-size: 16px;
    display: flex;
    gap: 4px;
    align-items: center;
    --color-link: var(--gw-brand-color-black-2);
    color: var(--color-link);
    margin-top: auto;
    margin-left: auto;

    svg {
        width: 16px;
        height: 16px;

        path[stroke="#F9FBFC"],
        ellipse[stroke="#F9FBFC"],
        circle[stroke="#F9FBFC"] {
            stroke: var(--gw-brand-color-red-1);
            transition: all .3s cubic-bezier(.645, .045, .355, 1);
        }
    }

    span {
        color: var(--color-link);
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }

    &:hover {
        --color-link: var(--gw-brand-color-red-1);

        span {
            color: var(--gw-brand-color-red-1);
        }
    }
}

.gw-header-map {
    border-radius: 8px;
    overflow: hidden;
}