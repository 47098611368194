.gw-comments {
    [class~=gw-list-comment] {
        display: flex;
        flex-direction: column;
        gap: 16px;
        [class~=gw-no-data] {
            text-align: center;
        }

        [class~=gw-item-comment] {
            display: flex;
            flex-direction: column;

            [class~=gw-comment-detail] {
                display: flex;
                gap: 10px;

                [class~=gw-image-profile] {
                    width: 31px;
                    height: 31px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                [class~=gw-comment-content] {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    [class~=gw-user-info] {
                        display: flex;
                        gap: 8px;

                        [class~=gw-username] {
                            span {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 21px;
                                color: #000000;
                            }
                        }

                        [class~=gw-block-dot] {
                            [class~=gw-dot] {
                                width: 4px;
                                height: 4px;
                                margin-bottom: 2px;

                                circle {
                                    fill: var(--gw-brand-color-gray-1);
                                }
                            }
                        }

                        [class~=gw-date] {
                            span {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: var(--gw-brand-color-black-2);
                            }
                        }
                    }

                    [class~=gw-comment-value] {
                        [class~=gw-comment-type-1] {
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 18px;
                            color: var(--gw-brand-color-black-1);
                            opacity: 0.8;
                        }

                        [class~=gw-comment-type-2] {
                            img {
                                max-width: 200px;
                                max-height: 200px;
                                width: 100%;
                                height: 100%;
                                object-fit: scale-down;
                            }
                        }
                    }

                    &>[class~=gw-item-comment] {
                        margin-top: 12px;
                    }

                    [class~=gw-action-comment] {
                        display: flex;

                        [class~=gw-btn-reply] {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            cursor: pointer;

                            span {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                color: var(--gw-brand-color-red-1);
                            }
                        }
                    }
                }
            }
        }
    }

    [class~=gw-comment-input] {
        margin-top: 32px;
        display: flex;
        align-items: flex-start;
        gap: 8px;

        [class~=gw-input-addons],
        [class~=gw-input-user-profile] {
            margin-top: 2px;
        }

        &[class~=has-reply] {
            align-items: flex-start;

            [class~=gw-input-addons],
            [class~=gw-input-user-profile] {
                margin-top: 67px;
            }
        }

        [class~=gw-input-addons] {
            display: flex;
            gap: 7px;

            button {
                padding: 0;
                width: 32px;
                height: 32px;
                background: #FFFFFF;
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                border-radius: 4px;
                border: none;

                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.50);
                }

                svg:not([class]) {
                    width: 16px;
                }

                position: relative;

                svg[class~=gw-addsquare] {
                    position: absolute;
                    top: 7px;
                    right: 6px;
                }
            }
        }

        [class~=gw-input-user-profile] {
            [class~=gw-image-profile] {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
                filter: drop-shadow(0px 0px 60px rgba(70, 13, 92, 0.1));
            }
        }

        [class~=gw-input-box] {
            width: 100%;

            [class~=ant-input-affix-wrapper] {
                width: 100%;
                height: 35px;
                border-radius: 114.362px;
                background-color: var(--gw-brand-color-white-1);
                border: none;
                box-shadow: none;
                border: 1px solid white;
                padding: 0px 11px 0px 17px;

                input {
                    background-color: transparent;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--gw-brand-color-black-1);
                }

                &[class~=ant-input-affix-wrapper-focused],
                &[class~=ant-input-affix-wrapper]:focus {
                    border: 1px solid var(--gw-brand-color-red-1);
                }

                [class~=ant-input-suffix] {
                    display: flex;
                    justify-content: center;
                    gap: 4px;
                }
            }

            [class~=gw-reply-container] {
                background-color: var(--gw-brand-color-white-1);
                border-radius: 25px;
                padding: 10px;
                display: flex;
                justify-content: space-between;

                [class~=gw-user-info] {
                    display: flex;
                    gap: 8px;

                    [class~=gw-username] {
                        span {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 21px;
                            color: #000000;
                        }
                    }

                    [class~=gw-block-dot] {
                        [class~=gw-dot] {
                            width: 4px;
                            height: 4px;
                            margin-bottom: 2px;

                            circle {
                                fill: var(--gw-brand-color-gray-1);
                            }
                        }
                    }

                    [class~=gw-date] {
                        span {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: var(--gw-brand-color-black-2);
                        }
                    }
                }

                [class~=gw-reply-action] {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    gap: 0;
                    padding-left: 15px;
                    justify-content: center;
                    align-content: flex-start;
                    
                    [class~=gw-btn-link][class~=btn-coin] {
                        margin-top: 10px;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                [class~=gw-reply-close] {
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    * {
                        transition: all .15s cubic-bezier(.645,.045,.355,1);
                    }
                    svg {
                        width: 100%;
                        height: 100%;
                        fill: #9c9c9c;
                    }
                    &:hover {
                        svg {
                            fill: var(--gw-brand-color-red-1);
                        }
                    }
                }
                
                [class~=gw-comment-preview-image] {
                    max-width: 200px;
                    max-height: 200px;
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
        }
        
    }
    
    [class~=gw-comment-type-3] {
        display: flex;
        gap: 16px;
        padding: 8px 10px;
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        border-radius: 8px;

        [class~=gw-image-location] {
            width: 84.89px;
            height: 60.11px;
            border-radius: 8px;
            object-fit: cover;
        }

        [class~=gw-comment-value-detail] {
            display: flex;
            flex-direction: column;

            [class~=title] {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                cursor: pointer;

                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }

            [class~=price] {
                display: flex;
                align-items: center;
                gap: 4px;

                font-size: 12px;
                line-height: 16px;

                svg {
                    height: 10px;
                    width: 10px;
                }

                [class~=value] {
                    font-weight: 400;
                    color: var(--gw-brand-color-black-1);
                }

                [class~=unit] {
                    font-weight: 700;
                    color: var(--gw-brand-color-red-1);
                }
            }
        }
    }
}