.navigate-container {
    position: relative;
    min-height: calc(100vh - 80px - 32px);
    .btn-navigate-action {
        position: fixed;
        z-index: 2;
        right: 16px;
        bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: var(--gw-brand-color-red-1);
            color: white;
            width: 64px;
            height: 64px;
            box-shadow: 0px 8px 16px rgba(28, 143, 108, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            img {
                height: 24px;
                filter: grayscale(1) brightness(500%);
            }
            &:hover,
            &:focus {
                box-shadow: 0px 19px 37px rgba(28, 143, 108, 0.35);
            }
        }
        
        @media screen and (max-width: 767px) {
            bottom: 80px;
        }
    }
}

.gw-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .gw-header-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .gw-title {
            display: flex;
            color: var(--gw-brand-color-red-1);
            font-size: 20px;
        }
        .gw-sub-title {
            color: var(--gw-brand-color-black-1);
            font-size: 16px;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
    .gw-header-action {
        display: flex;
        align-items: flex-end;
        button {
            padding: 0;
            height: 32px;
            font-size: 16px;
            display: flex;
            align-items: center;
            &.btn-link {
                --color-link: var(--gw-brand-color-black-2);
                color: var(--color-link);
                svg {
                    width: 16px;
                    height: 16px;
                    path[stroke="#F9FBFC"],
                    ellipse[stroke="#F9FBFC"],
                    circle[stroke="#F9FBFC"] {
                        stroke: var(--color-link);
                        transition: all .3s cubic-bezier(.645,.045,.355,1);
                    }
                }
                &:hover {
                    --color-link: var(--gw-brand-color-red-1)
                }
            }
        }
    }
    [class~=ant-select] {
        width: 160px;
        [class~=ant-select-selector] {
            border-radius: 8px;
            border-color: var(--gw-brand-color-gray-1);
            height: 34px;

            [class~=ant-select-selection-search-input] {
                height: 32px;
            }
        }
        [class~=ant-select-arrow] {
            top: 35%;
            right: 17px;
        }
        &:not([class~=ant-select-disabled]) {
            &:hover,
            &:focus,
            &[class~=ant-select-focused] {
                [class~=ant-select-selector] {
                    border-color: var(--gw-brand-color-red-1);
                    box-shadow: none;
                }
            } 
        }
    }
}

[class~=gw-list-loadmore-property] {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}

.gw-header {
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 12px;
    .block-top-profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .gw-status {
            margin-top: 9px;
            display: flex;
            gap: 23.5px;
            justify-content: flex-end;

            .gw-group-text {
                display: flex;
                flex-direction: column;

                span.gw-text-count {
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 42px;

                    color: var(--gw-brand-color-red-1);
                }

                span.gw-text-detail {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;

                    color: var(--gw-brand-color-black-1);

                    @media screen and (max-width: 767px) {
                        font-size: 12px;
                        white-space: nowrap;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                gap: 10px;
            }
        }

        .gw-user-info {
            display: flex;
            gap: 16px;
            align-items: center;

            .gw-image-profile {
                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    object-fit: cover;

                    @media screen and (max-width: 767px) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }

            .gw-user-details {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .gw-user-name {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    span {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 32px;
                        color: var(--gw-brand-color-black-1);

                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                        }
                    }

                    span {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }
                    
                    [class~=gw-user-action] {
                        display: flex;
                        align-items: center;
                        button {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0;
                            &:hover {
                                border-color: #DD1508;
                            }
                        }
                        [class~=btn-chat] {
                            svg {
                                path,
                                ellipse,
                                circle {
                                    fill: #DD1508;
                                }
                            }
                        }
                    }
                }

                .gw-user-contact {
                    display: flex;
                    gap: 8px;

                    .gw-btn-circle {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        min-height: 20px;
                        padding: 0;
                        border: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--gw-brand-color-gray-1);
                        // background-color: var(--gw-brand-color-red-1);

                        svg {
                            width: 12px;
                            height: 12px;
                        }

                        &.gw-active {
                            background-color: var(--gw-brand-color-red-1);
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: normal;

            .gw-status {
                margin-top: 0;
            }
        }
    }
    .gw-description {
        display: flex;
        flex-direction: column;
        span.gw-text-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;

            color: var(--gw-brand-color-red-1);
        }
        span.gw-text-detail {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;

            color: var(--gw-brand-color-black-2);
            opacity: 0.8;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 4px 0px;

        }
    }
    .gw-list-document {
        margin-top: 21px;
        .gw-item-document {
            width: 100%;
            display: flex;
            gap: 8px;
            align-items: center;
            [stroke="#DD1508"] {
                stroke: var(--gw-brand-color-other-1);
            }
            &.gw-item-active {
                .gw-icon {
                    [stroke="#DD1508"] {
                        stroke: var(--gw-brand-color-red-1);
                    }
                }
            }
            .gw-icon {
                width: 32px;
                height: 32px;
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
            .gw-label {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;

                color: #212B36;
            }
            .gw-suffix {
                border: 0;
                margin-left: auto;
                display: flex;
                padding: 0;
                height: auto;
                width: auto;
                min-height: auto;
                min-width: auto;
            }
        }
    }
    .gw-btn-edit {
        width: 292px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background-color: var(--gw-brand-color-red-1);
        color: #ffffff;
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        border-radius: 8px;
    }
}