.navigate-container {
    position: relative;
    min-height: calc(100vh - 80px - 32px);
    .btn-navigate-action {
        position: fixed;
        z-index: 2;
        right: 16px;
        bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: var(--gw-brand-color-red-1);
            color: white;
            width: 64px;
            height: 64px;
            box-shadow: 0px 8px 16px rgba(28, 143, 108, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            img {
                height: 24px;
                filter: grayscale(1) brightness(500%);
            }
            &:hover,
            &:focus {
                box-shadow: 0px 19px 37px rgba(28, 143, 108, 0.35);
            }
        }
        
        @media screen and (max-width: 767px) {
            bottom: 80px;
        }
    }
}

.gw-header {
    display: flex;
    .gw-header-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .gw-title {
            display: flex;
            color: var(--gw-brand-color-red-1);
            font-size: 20px;
            align-items: center;
            gap: 14px;
            .logo {
                height: 25px;
            }

            .gw-title-sub {
                color: #3a3a3a;
                font-size: 14px;
                font-style: italic;
                font-weight: 500;
            }
        }
        .gw-sub-title {
            color: var(--gw-brand-color-black-1);
            font-size: 16px;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
    .gw-header-action {
        display: flex;
        align-items: flex-end;
        button {
            padding: 0;
            height: 32px;
            font-size: 16px;
            display: flex;
            align-items: center;
            &.btn-link {
                --color-link: var(--gw-brand-color-black-2);
                color: var(--color-link);
                svg {
                    width: 16px;
                    height: 16px;
                    path[stroke="#F9FBFC"],
                    ellipse[stroke="#F9FBFC"],
                    circle[stroke="#F9FBFC"] {
                        stroke: var(--color-link);
                        transition: all .3s cubic-bezier(.645,.045,.355,1);
                    }
                }
                &:hover {
                    --color-link: var(--gw-brand-color-red-1)
                }
            }
        }
    }
}

[class~=gw-list-loadmore-property] {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}