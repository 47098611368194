.gw-header-base {
    display: flex;
    align-items: center;
    gap: 8px;
    min-height: 32px;
}
.gw-header {
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.gw-text-title-1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--gw-brand-color-black-1);
}

.gw-text-indent {
    text-indent: 40px;
}