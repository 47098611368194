:root {
    --gw-container-w: 1280px;
}

:root {
    --gw-brand-background-1: #F9F9F9;
    --gw-brand-color-red-1: #DD1508;
    --gw-brand-color-red-1-rgb: 221, 21, 8;
    --gw-brand-color-red-2: #9E0F06;
    --gw-brand-color-black-1: #333333;
    --gw-brand-color-black-2: #3B3B3B;
    --gw-brand-color-gray-1: #CED4D9;
    --gw-brand-color-gray-2: #E3EAEF;
    --gw-brand-color-white-1: #F9FBFC;
    --gw-brand-color-orenge-1: #F48020;
    --gw-brand-color-brown-1: #7C4A32;
    --gw-brand-color-other-1: #DB765E;
    --gw-brand-color-other-2: #C774DB;
}

:root {
    --antd-wave-shadow-color: #DD1508;
}

body {
    background-color: var(--gw-brand-background-1);
    min-width: var(--gw-container-w);
}

@font-face {
    font-family: 'Sarabun';
    src: url('../fonts/Sarabun/Sarabun-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Sarabun';
    src: url('../fonts/Sarabun/Sarabun-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Sarabun';
    src: url('../fonts/Sarabun/Sarabun-Bold.ttf') format('truetype');
    font-weight: 700;
}

span,
input,
textarea {
    font-family: "Sarabun";
}

[class~=ant-drawer-mask] {
    background-color: #0000000a;
}

// img[src $= ".png"], img[src $= ".jpg"], img[src $= ".jpeg"] {
//     image-rendering: -moz-crisp-edges;
//     image-rendering: -o-crisp-edges;
//     image-rendering: -webkit-optimize-contrast;
//     image-rendering: crisp-edges;
//     -ms-interpolation-mode: nearest-neighbor;
// }

@media (max-width: 767px) {
    body {
        min-width: 100%;
        max-width: 100%;
    }
}