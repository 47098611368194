.gw-block-container {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 12px;
    padding: 24px;

    --gw-custom-height: 48px;

    [class~=ant-form-item] {
        margin-bottom: 0;
    }

    .gw-text-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: var(--gw-brand-color-red-1);
    }

    .gw-text-sub-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--gw-brand-color-black-1);
        opacity: 0.64;
    }

    .gw-form-selector {
        [class~=ant-select-selection-placeholder] {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: var(--gw-brand-color-black-1);
            opacity: 0.80;
        }

        [class~=ant-select-arrow] {
            top: 40%;
            right: 17px;
        }

        [class~=ant-select] {
            [class~=ant-select-selector] {
                border-radius: 8px;
                border-color: var(--gw-brand-color-gray-1);
                height: var(--gw-custom-height);
                display: flex;
                align-items: center;

                [class~=ant-select-selection-search-input] {
                    height: calc(var(--gw-custom-height) - 2px);

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--gw-brand-color-black-1);
                }
            }

            &:not([class~=ant-select-disabled]) {

                &:hover,
                &:focus,
                &[class~=ant-select-focused] {
                    [class~=ant-select-selector] {
                        border-color: var(--gw-brand-color-red-1);
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .gw-form-input {

        [class~=ant-form-item-control-input-content]>[class~=ant-input],
        [class~=ant-input-affix-wrapper],
        [class~=ant-input-number-affix-wrapper] {
            width: 100%;
            height: var(--gw-custom-height);
            border-radius: 8px;
            overflow: hidden;
            border-color: var(--gw-brand-color-gray-1);
            box-shadow: none;

            [class~=ant-input-number-input] {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                height: calc(var(--gw-custom-height) - 2px);

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: var(--gw-brand-color-black-1);
                    opacity: 0.80;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: var(--gw-brand-color-black-1);
                    opacity: 0.80;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: var(--gw-brand-color-black-1);
                    opacity: 0.80;
                }
            }

            &:not([disabled]),
            &:not([class~=ant-input-number-affix-wrapper-disabled]),
            &:not([class~=ant-input-affix-wrapper-disabled]) {

                &:hover,
                &:focus,
                &[class~=ant-input-number-affix-wrapper-focused],
                &[class~=ant-input-affix-wrapper-focused] {
                    border-color: var(--gw-brand-color-red-1);
                }
            }

            [class~=ant-input-number-prefix] {
                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            [class~=ant-input-suffix] {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: var(--gw-brand-color-red-1);
            }
        }
        &[class~=ant-form-item-has-error] {
            [class~=ant-form-item-control-input-content]>[class~=ant-input],
            [class~=ant-input-affix-wrapper],
            [class~=ant-input-number-affix-wrapper] {
                border-color: #ff4d4f;
                &:not([disabled]),
                &:not([class~=ant-input-number-affix-wrapper-disabled]),
                &:not([class~=ant-input-affix-wrapper-disabled]) {
    
                    &:hover,
                    &:focus,
                    &[class~=ant-input-number-affix-wrapper-focused],
                    &[class~=ant-input-affix-wrapper-focused] {
                        border-color: #ff4d4f;
                    }
                }
            }
        }
    }

    .gw-form-textarea {
        [class~=ant-input] {
            padding: 11px 16px;
            border-color: var(--gw-brand-color-gray-1);
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            box-shadow: none;

            &:not([class~=ant-input-disabled]) {

                &[class~=ant-input-focused],
                &[class~=ant-input]:hover,
                &[class~=ant-input]:focus {
                    border-color: var(--gw-brand-color-red-1);
                }
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: var(--gw-brand-color-black-1);
                opacity: 0.80;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: var(--gw-brand-color-black-1);
                opacity: 0.80;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: var(--gw-brand-color-black-1);
                opacity: 0.80;
            }
        }
    }

    .gw-form-upload {
        [class~=ant-upload-list-picture-card-container] {
            margin: 0 11px 8px 0;

        }

        [class~=ant-upload-list-picture-card] [class~=ant-upload-list-item],
        [class~=ant-upload-list-picture] [class~=ant-upload-list-item] {
            padding: 5px;
        }

        [class~=ant-upload][class~=ant-upload-select-picture-card] {
            background-color: var(--gw-brand-color-white-1);

        }
    }

    .gw-form-switch {
        [class~=ant-switch] {
            min-width: 34px;
            height: 14px;
            background-color: #333333cc;

            &[class~=ant-switch-checked] {
                background-color: rgba(var(--gw-brand-color-red-1-rgb), 0.32);

                [class~=ant-switch-handle] {
                    left: calc(100% - 20px);

                    &:before {
                        background-color: var(--gw-brand-color-red-1);

                    }
                }
            }

            [class~=ant-switch-handle] {
                top: -3px;
                left: -1px;
                width: 20px;
                height: 20px;

                &:before {
                    background-color: var(--gw-brand-color-white-1);
                    border-radius: 50%;
                }
            }
        }
    }

    .gw-form-list {
        --gw-custom-height: 40px;

        .gw-text-topic {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: var(--gw-brand-color-black-1);
        }
    }

    .btn-form-action {
        display: flex;
        align-items: flex-start;
    }

    .btn-form {
        width: 164px;
        height: 48px;

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
        }
    }

    .gw-w-100 {
        width: 100%;
    }
    [class~=ant-upload][class~=ant-upload-select-picture-card],
    [class~=ant-upload-list-picture-card-container] {
        width: 150px;
        height: 150px;
    }
}

button.btn-form {
    height: 48px;
    width: 194px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 400;

    >div {
        display: flex;
        justify-content: center;
        gap: 8px;
    }

    &.gw-btn-clear:not([disabled]) {
        background: var(--gw-brand-color-white-1);
        border: 1px solid var(--gw-brand-color-gray-2);
        color: var(--gw-brand-color-black-1);
    }

    &.gw-btn-confirm:not([disabled]) {
        border: 0;
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        background-color: var(--gw-brand-color-red-1);
        color: #ffffff;
    }
}

.btn-group-footer {
    button.btn-form {
        height: 40px;
        width: 194px;
        border-radius: 8px;
        span {
            font-size: 18px;
            font-weight: 400;
        }
    }

}

.gw-btn-actions {
    padding-top: 48px;
    button.btn-form {
        height: 40px;
        width: 194px;
        border-radius: 8px;
        span {
            font-size: 18px;
            font-weight: 400;
        }
    }
}