.gw-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    [class~=ant-select] {
        width: 160px;
        [class~=ant-select-selector] {
            border-radius: 8px;
            border-color: var(--gw-brand-color-gray-1);
            height: 34px;

            [class~=ant-select-selection-search-input] {
                height: 32px;
            }
        }
        [class~=ant-select-arrow] {
            top: 35%;
            right: 17px;
        }
        &:not([class~=ant-select-disabled]) {
            &:hover,
            &:focus,
            &[class~=ant-select-focused] {
                [class~=ant-select-selector] {
                    border-color: var(--gw-brand-color-red-1);
                    box-shadow: none;
                }
            } 
        }
    }
}