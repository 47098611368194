.gw-drawer-filter {
    &[class~=ant-drawer-open] {
    }
    [class~="ant-drawer-header"] {
        display: none;
    }
    [class~=ant-drawer-content-wrapper] {
        max-width: 26.8% !important;
        min-width: 350px;
    }
    [class~="ant-drawer-body"] {
        background-color: var(--gw-brand-background-1);
    }
    .gw-dw-content {
        max-width: 100%;
    }
    .gw-dw-header {
        display: flex;
        .gw-header-text {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .gw-title {
                display: flex;
                color: var(--gw-brand-color-red-1);
                font-size: 24px;
            }
            .gw-sub-title {
                color: var(--gw-brand-color-black-1);
                font-size: 16px;

                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 30px;
                }
            }
        }
        .gw-header-action {
            display: flex;
            align-items: flex-end;
            button {
                padding: 0;
                height: 22px;
                font-size: 14px;
                &.btn-back {
                    color: var(--gw-brand-color-other-1);
                }
            }
        }
    }
    .gw-dw-form {
        margin-top: 24px;
        .text-title {
            font-weight: 500;
            font-size: 16px;
            color: var(--gw-brand-color-black-1);
        }

        .field-label {
            font-weight: 500;
            font-size: 16px;
            color: var(--gw-brand-color-red-1);
        }

        [class~=ant-col-24][class~=ant-form-item-label] {
            padding: 0;
        }

        [class~=filter-input-text] {
            [class~=ant-input-affix-wrapper]:not([class~=ant-input-affix-wrapper-disabled]) {
                box-shadow: none;
                border-color: var(--gw-brand-color-gray-1);
                border-radius: 8px;
                &:hover,
                &:focus,
                &[class~=ant-input-affix-wrapper-focused] {
                    border-color: var(--gw-brand-color-red-1) !important;
                }
                [class~=ant-input-focused],
                [class~=ant-input]:focus {
                    border-color: var(--gw-brand-color-red-1);
                }
            }
        }

        [class~=filter-dropdown] {
            [class~=ant-select-arrow] {
                top: 35%;
                right: 17px;
            }
            [class~=ant-select] {
                [class~=ant-select-selector] {
                    border-radius: 8px;
                    border-color: var(--gw-brand-color-gray-1);
                    height: 34px;

                    [class~=ant-select-selection-search-input] {
                        height: 32px;
                    }
                }
                &:not([class~=ant-select-disabled]) {
                    &:hover,
                    &:focus,
                    &[class~=ant-select-focused] {
                        [class~=ant-select-selector] {
                            border-color: var(--gw-brand-color-red-1);
                            box-shadow: none;
                        }
                    } 
                }
            }
        }
        
        [class~=filter-input-range] {
            [class~=ant-slider-handle] {
                background-color: var(--gw-brand-color-red-1);
                border: 2px solid var(--gw-brand-color-red-1);
            }
            [class~=ant-slider-track] {
                background-color: var(--gw-brand-color-red-1);
                height: 2px;
            }
            [class~=ant-slider-rail] {
                background-color: var(--gw-brand-color-red-2);
                opacity: 38%;
                height: 2px;
            }
            [class~=ant-slider-handle] {
                width: 12px;
                height: 12px;
            }
        }

        [class~=filter-button-radio] {
            [class~=ant-radio-group] {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
            }

            [class~=ant-radio-button-wrapper] {
                height: 40px;
                line-height: 38px;
                min-width: 40px;
                padding: 0px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid var(--gw-brand-color-gray-1);
                &::before {
                    content: none;
                }
                &:hover {
                    &:not([class~=ant-radio-button-wrapper-checked]) {
                        color: var(--gw-brand-color-red-1);
                    }
                }
                &[class~=ant-radio-button-wrapper-checked]:not([class~=ant-radio-button-wrapper-disabled]) {
                    background: var(--gw-brand-color-red-1);
                    border-color: var(--gw-brand-color-red-1);
                }
            }
        }

        [class~=filter-button-checkbox] {
            [class~=ant-checkbox]+span {
                padding: 0;
            }
            [class~=ant-checkbox-group-item] {
                margin: 0;
            }
            [class~=ant-checkbox-inner] {
                display: none;
            }
            [class~=ant-checkbox-checked]:after {
                content: none;
            }

            [class~=ant-checkbox-group] {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
            }

            [class~=ant-checkbox-wrapper] {
                height: 40px;
                line-height: 38px;
                min-width: 40px;
                padding: 0px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid var(--gw-brand-color-gray-1);
                transition: color .3s,background .3s,border-color .3s,box-shadow .3s;
                
                &::before {
                    content: none;
                }
                &:hover {
                    &:not([class~=ant-checkbox-wrapper-checked]) {
                        color: var(--gw-brand-color-red-1);
                    }
                }
                &[class~=ant-checkbox-wrapper-checked]:not([class~=ant-radio-button-wrapper-disabled]) {
                    background: var(--gw-brand-color-red-1);
                    border-color: var(--gw-brand-color-red-1);
                    color: #ffffff;
                }
            }
        }
        [class~=filter-input-dropdown-range] {
            [class~=ant-btn] {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-radius: 8px;
                border-color: var(--gw-brand-color-gray-1);
                height: 34px;
                color: rgba(0,0,0,.85) !important;
                &:not([disabled]) {
                    &:hover,
                    &:focus,
                    &[class~=ant-dropdown-open] {
                        border-color: var(--gw-brand-color-red-1);
                        box-shadow: none;
                    } 
                }
                [class~=price-val] {
                    display: flex;
                    gap: 2px;
                }
            }
        }
        .gw-action {
            [class~=ant-form-item-control-input-content] {
                display: flex;
                justify-content: center;
                gap: 24px;
            }
            button {
                height: 40px;
                width: 194px;
                border-radius: 8px;
                font-size: 18px;
                font-weight: 400;
                > div {
                    display: flex;
                    justify-content: center;
                    gap: 8px;
                }
            }

            .gw-btn-clear {
                background: var(--gw-brand-color-gray-2);
                border: 1px solid var(--gw-brand-color-gray-1);
                color: var(--gw-brand-color-black-1);
            }
    
            .gw-btn-confirm {
                border: 0;
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                background-color: var(--gw-brand-color-red-1);
                color: #ffffff;
            }
        }
    }
}

.dropdown-input-range {
    [class~=dropdown-label] {
        color: var(--gw-brand-color-black-1);
        font-size: 16px;
    }
    [class~=ant-divider] {
        margin: 5px 0px;
    }
    [class~=dropdown-container] {
        padding: 5px 15px;
        display: flex;
        flex-direction: column;
    }
    [class~=input-range-container] {
        margin-top: 5px;
        display: flex;
        gap: 5px;
        [class~=input-range] {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 3px;
            [class~=ant-input-number],
            [class~=ant-input-number-affix-wrapper] {
                width: 120px;
            }
        }
        [class~=option-range] * {
            transition: all .2s cubic-bezier(.645,.045,.355,1)!important;
        }
        [class~=option-range] {
            transition: all .2s cubic-bezier(.645,.045,.355,1)!important;
            max-height: 0px;
            overflow: auto;
            li {
                a {
                    height: 30px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    color: rgba(0,0,0,.85);
                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
                &[class~=active] {
                    a {
                        background-color: #e6f7ff;
                    }
                }
            }
            &[class~=active] {
                max-height: 150px;
            }
        }
    }
    [class~=action-container] {
        display: flex;
        justify-content: space-between;
        [class~=ant-btn] {
            border-radius: 8px;
        }
        [class~=ant-btn-link] {
            color: var(--gw-brand-color-red-1);
        }
        [class~=ant-btn-primary] {
            border-color: var(--gw-brand-color-red-1);
            background: var(--gw-brand-color-red-1);
        }
    }
    [class~=ant-dropdown-menu-item] {
        &:hover {
            background-color: #fff;
        }
    }
}