.navigate-container {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 80px);
    [class~=ant-col] {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
    .col-map {
        &[class~=ant-col-18] {
            .btn-navigate-action {
                right: 24px;
            }
        }
    }
    .btn-navigate-action {
        position: absolute;
        z-index: 2;
        right: 16px;
        bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: var(--gw-brand-color-red-1);
            color: white;
            width: 64px;
            height: 64px;
            box-shadow: 0px 8px 16px rgba(28, 143, 108, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            &:hover,
            &:focus {
                box-shadow: 0px 19px 37px rgba(28, 143, 108, 0.35);
            }
        }
        
        @media screen and (max-width: 767px) {
            bottom: 20px;
        }
    }
    .btn-action-maps {
        position: absolute;
        z-index: 1;
        left: 80px;
        bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: white;
            color: white;
            width: 40px;
            height: 40px;
            box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            &:hover,
            &:focus {
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.70), 0px 20px 40px -4px rgba(140, 158, 173, 0.70);
            }

            @media screen and (max-width: 767px) {
                width: 50px;
                height: 50px;
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        
        @media screen and (max-width: 767px) {
            left: 20px;
        }
    }
    [class~=gw-search-resoult-list] {
        max-height: calc(100vh - 80px);
        overflow: auto;
        padding-bottom: 24px;
        
        @media screen and (max-width: 767px) {
            max-height: calc(100svh - 130px);
        }
    }
        
    @media screen and (max-width: 767px) {
        min-height: calc(100svh - 130px);
        padding-top: 65px;
    }
}

[class~=gw-search-result] {
    width: 295px;
    [class~=gw-btn-action] {
        display: flex;
    }
    [class~=gw-search-result-header] {
        display: flex;
        justify-content: space-between;
        [class~=gw-result-count] {
            display: flex;
            gap: 2px;
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: var(--gw-brand-color-red-1);
            }
        }
        [class~=gw-result-filter] {
            display: flex;
            align-items: center;
            gap: 2px;
            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                color: var(--gw-brand-color-black-2);
            }
            svg {
                [stroke="#F9FBFC"] {
                    stroke: var(--gw-brand-color-red-1);
                }
            }
        }
    }
}

.gw-map {
    [class~=gm-bundled-control][class~=gm-bundled-control-on-bottom] {
        right: 120px !important;
        bottom: 97px !important;
    }
}

.gw-cluster {
    box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
    border-radius: 8px;
}

[class~=ant-progress-status-success] [class~=ant-progress-bg],
[class~=ant-progress-bg], [class~=ant-progress-success-bg] {
    background-color: var(--gw-brand-color-red-1);
}

.disabled-page {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

