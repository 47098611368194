.navigate-container {
    position: relative;
    min-height: calc(100vh - 80px - 32px);
    .btn-navigate-action {
        position: fixed;
        z-index: 2;
        right: 16px;
        bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: var(--gw-brand-color-red-1);
            color: white;
            width: 64px;
            height: 64px;
            box-shadow: 0px 8px 16px rgba(28, 143, 108, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            img {
                height: 24px;
            }
            &:hover,
            &:focus {
                box-shadow: 0px 19px 37px rgba(28, 143, 108, 0.35);
            }
        }
        
        @media screen and (max-width: 767px) {
            bottom: 80px;
        }
    }
}

.gw-text-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .gw-text-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        color: var(--gw-brand-color-red-1);
    }

    .gw-text-detail {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: var(--gw-brand-color-black-1);
    }
}

.gw-menu-sidebar {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 12px;
    padding: 16px;
    margin-top: 16px;

    .gw-user-info {
        display: flex;
        gap: 16px;
        align-items: center;

        .gw-image-profile {
            img {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .gw-user-details {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .gw-user-name {
                span {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 32px;
                    color: var(--gw-brand-color-black-1);
                }

                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                }
            }

            .gw-user-contact {
                display: flex;
                gap: 8px;

                .gw-btn-circle {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    padding: 0;
                    border: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--gw-brand-color-gray-1);
                    // background-color: var(--gw-brand-color-red-1);

                    svg {
                        width: 12px;
                        height: 12px;
                    }

                    &.gw-active {
                        background-color: var(--gw-brand-color-red-1);
                    }
                }
            }
        }
    }

    .gw-menu-list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .gw-menu-item {
            gap: 16px;

            .gw-icon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    [fill="#DD1508"] {
                        fill: var(--gw-brand-color-black-1);
                    }
                    [stroke="#DD1508"] {
                        stroke: var(--gw-brand-color-black-1);
                    }
                    [id="gw-path"] {
                        stroke: var(--gw-brand-color-black-1) !important;
                    }
                }
            }

            .gw-label {
                span {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    color: var(--gw-brand-color-black-1);
                }

            }

            &.active {
                .gw-icon {
                    svg {
                        [fill="#DD1508"] {
                            fill: var(--gw-brand-color-red-1);
                        }
                        [stroke="#DD1508"] {
                            stroke: var(--gw-brand-color-red-1);
                        }
                        [id="gw-path"] {
                            stroke: var(--gw-brand-color-red-1) !important;
                        }
                    }
                }
                .gw-label {
                    span {
                        color: var(--gw-brand-color-red-1);
                    }
                }
            }
            &.gw-premium {
                &::after {
                    content: "PREMIUM";
                    border: 1px solid #DD1508;
                    padding: 0px 5px;
                    border-radius: 5px;
                    color: #DD1508;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 21px;
                }
                .gw-icon {
                    opacity: 0.6;
                }
                .gw-label {
                    opacity: 0.6;
                }
            }
        }
    }
}

.gw-content {
    [class~=gw-text-title] {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
    
        color: var(--gw-brand-color-red-1);
    }
}

.gw-container {
    position: sticky;
    top: 112px;
}

.gw-img-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    max-width: var(--gw-container-w);
    margin: 0 auto;
    padding: 0 5px;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    .gw-img {
        width: 100%;
        max-width: 250px;
    }
    .gw-block-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
        max-width: 250px;
        .gw-img-2 {
            width: 100%;
            object-fit: contain;
            max-width: 120px;
            height: 42px;
            cursor: pointer;
        }
        .gw-img-3 {
            width: 100%;
            object-fit: fill;
            max-width: 120px;
            height: 42px;
            cursor: pointer;
        }
    }
}