[class~=gw-carousel] {
    padding-bottom: 81px;

    [class~=gw-header] {
        display: flex;
        align-items: center;
        justify-content: space-between;

        [class~=gw-left] {
            display: flex;
            align-items: flex-end;
            gap: 16px;

            [class~=gw-heaer-text] {
                span {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 23px;
                    color: var(--gw-brand-color-black-1);
                }
            }

            [class~=gw-btn-link] {
                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--gw-brand-color-red-1);
                }
            }
        }

        [class~=gw-right] {
            display: flex;
            gap: 8px;

            button {
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                width: 32px;
                height: 32px;
                border: 0;
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                border-radius: 8px;
                background-color: var(--gw-brand-color-white-1);
            }
        }
    }

    [class~=gw-carousel-body] {
        [class~=gw-list-item] {
            >[class~=slick-list] {
                margin: 0 -10px;

                >[class~=slick-track]>[class~=slick-slide]>div {
                    margin: 0 10px;
                }
            }
        }
    }
}