.gw-btn-option {
    &[ant-click-animating-without-extra-node="true"]::after {
        --antd-wave-shadow-color: #DD1508;
    }

    padding: 0;
    border: none;
    box-shadow: none;
    background: transparent !important;

    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;

    [stroke="#DD1508"] {
        stroke: var(--gw-brand-color-other-1);
    }

    &.gw-item-active {
        .gw-icon {
            [stroke="#DD1508"] {
                stroke: var(--gw-brand-color-red-1);
            }
        }
    }

    .gw-icon {
        width: 32px;
        height: 32px;
        box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .gw-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;

        color: var(--gw-brand-color-red-1);
    }

    .gw-suffix {
        border: 0;
        margin-left: auto;
        display: flex;
        padding: 0;
        height: auto;
        width: auto;
        min-height: auto;
        min-width: auto;
    }
}