.gw-side-menu {
    .gw-header {
        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: var(--gw-brand-color-red-1);
        }
    }

    .gw-list-menu {
        margin-top: 45px;

        [class~=ant-btn] {
            margin-bottom: 16px;
            width: 100%;
            height: 80px;
            border: none;
            padding: 24px;
            border-radius: 12px;
            box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
            background-color: #EFF0F0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            span {
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;

                color: var(--gw-brand-color-black-1);
                opacity: 0.72;
            }
            &[disabled] {
                cursor: default;
                background-color: #ffffff;
                span {
                    color: var(--gw-brand-color-red-1);
                    opacity: 1;
                }
            }
            &:not([disabled]):focus,
            &:not([disabled]):hover {
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.60);
            }
            .gw-btn-icon {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                svg {
                    [fill="#DD1508"] {
                        fill: #333333;
                        fill-opacity: 0.72;
                    }
                }
            }
            &[disabled] {
                .gw-btn-icon {
                    
                    border-radius: 50%;
                    box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                    svg {
                        [fill="#333333"] {
                            fill: #DD1508;
                            fill-opacity: 0.72;
                        }
                    }
                }
            }
        }
    }
}

.gw-feed-content {
    .gw-feed-list {
        padding-bottom: 24px;
    }
    .gw-detail-broadcast {
        padding: 15px 15.8px;
        [class~=gw-card-text-content] {
            display: flex;
            flex-direction: column;
            [class~=gw-text-title] {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: var(--gw-brand-color-red-1);
            }
            [class~=gw-text-details] {
                margin-top: 6px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: var(--gw-brand-color-black-2);
                &[class~=gw-title] {
                    font-size: 14px;
                }
            }
        }
        [class~=gw-map] {
            margin-top: 24px;
        }
        [class~=gw-card-details] {
            [class~=gw-card-detail-left] {
                [class~=gw-price-title] {
                    span {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        color: var(--gw-brand-color-red-1);
                    }
                }
                [class~=price] {
                    > span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        span {
                            color: var(--gw-brand-color-black-2);
                        }
                    }
                }
            }
        }
        [class~=gw-card-comment] {
            [class~=gw-text-title] {
                span {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                    color: var(--gw-brand-color-red-1);
                }
            }
        }
    }
}