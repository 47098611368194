.navigate-container {
    position: relative;
    min-height: calc(100vh - 80px - 32px);
    .btn-navigate-nearby-place {
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: #00000000;
        cursor: pointer;
        border-radius: 8px;
        transition: all .3s;
        &:hover {
            background-color: #4646463b;
        }
    }
    .btn-navigate-action {
        position: fixed;
        z-index: 2;
        right: 16px;
        bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: var(--gw-brand-color-red-1);
            color: white;
            width: 64px;
            height: 64px;
            box-shadow: 0px 8px 16px rgba(28, 143, 108, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            img {
                height: 24px;
            }
            &:hover,
            &:focus {
                box-shadow: 0px 19px 37px rgba(28, 143, 108, 0.35);
            }
        }
        
        @media screen and (max-width: 767px) {
            bottom: 80px;
        }
    }
}

.gw-header {
    display: flex;
    position: relative;

    [class~=gw-travel-time] {
        --size-card: 300px;
        position: absolute;
        right: calc(-1 * var(--size-card) - 16px);
        width: calc(var(--size-card));
        background-color: #fff;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 8px;
        padding: 16px 12px;
    }
}
.navigate-container {
    [class~=gw-card-list-image] {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 10.5px;
        [class~=ant-image-mask] {
            opacity: 1;
        }
        [class~=gw-preview-mask] {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: var(--gw-brand-color-white-1);
            }
        }

        [class~=ant-image] {
            cursor: pointer;
            border-radius: 8px;
            overflow: hidden;
        }
        
        [class~=row-1],
        [class~=row-2] {
            display: flex;
            gap: 16px;
            [class~=ant-image] {
                width: 100%;
                border-radius: 8px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        [class~=row-1] {
            [class~=ant-image] {
                height: 211px;
            }
        }
        [class~=row-2] {
            [class~=ant-image] {
                height: 136px;
            }
        }
    }
}

[class~=gw-body-details] {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 12px;
    padding: 17px 24px 29px;
    [class~=gw-layer-1] {
        span {
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            color: var(--gw-brand-color-black-1);
        }
    }
    [class~=gw-layer-2] {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        [class~=gw-card-detail-left] {
            color: var(--gw-brand-color-black-1);
            display: flex;
            flex-direction: column;
            [class~=price] {
                display: flex;
                align-items: center;
                gap: 8px;

                svg path {
                    fill: var(--gw-brand-color-black-1);
                }

                [class~=price-unit ] {
                    display: flex;
                    gap: 4px;
                    line-height: 24px;
                    font-size: 18px;
                    font-weight: 500;
                    [class~=unit ] {
                        color: var(--gw-brand-color-black-1);
                    }
                    [class~=value] {
                        color: var(--gw-brand-color-black-1);
                    }
                }

                [class~=gw-badge-sale] {
                    margin-left: 8px;

                    padding: 0px 8.5px;
                    height: 24px;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    background-color: var(--gw-brand-color-red-1);
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            
            [class~=area] {
                line-height: 18px;
                font-size: 14px;
                font-weight: 400;
            }
            
        }
        
        [class~=gw-card-detail-right] {
            --color: #3b3b3bb3;
            display: flex;
            flex-direction: column;
            gap: 3px;

            [class~=text-icon] {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                display: flex;
                gap: 2px;
                align-items: center;
            }

            [class~=text-icon] span {
                color: var(--color);
            }

            [class~=text-icon] svg {
                path {
                    fill: var(--color);
                }
            }
    
            [class~=layer-1] {
                display: flex;
                gap: 16px;
            }
            
            [class~=layer-2] {
                display: flex;
            }
        }
    }
    [class~=gw-layer-3] {
        margin-top: 15px;
        [class~=gw-card-location] {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
                color: var(--gw-brand-color-black-1);
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                opacity: .64;
            }
            [class~=ant-breadcrumb-separator] {
                margin: 0px 2px;
            }
            [class~=gw-dot] {
                width: 4px;
                height: 4px;
                margin-bottom: 2px;
                circle {
                    fill: var(--gw-brand-color-gray-1);
                }
            }
        }
    }
    [class~=gw-btn-sold] {
        width: 292px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background-color: #F48020;
        color: #ffffff;
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        border-radius: 8px;
        margin: auto;
        
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        &[disabled] {
            pointer-events: none;
        }
    }
    [class~=gw-btn-action] {
        display: flex;
        justify-content: center;
        gap: 84.5px;
        [class~=gw-btn-circle] {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            * {
                transition: all .3s cubic-bezier(.645,.045,.355,1);
            }
            [class~=btn-circle] {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 24px;
                    height: 24px;
                }
            }
            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                
                color: var(--gw-brand-color-black-1);
                opacity: 0.8;
            }
            &[class~=btn-coin] {
                svg * {
                    filter: none;
                    -webkit-filter: grayscale(1);
                }
                path[fill="#FED843"],
                path[fill="#FED843"] + path[fill="#FABE2C"] {
                    fill: #ffffff;
                }

                &[class~=active] {
                    svg * {
                        filter: none;
                        -webkit-filter: grayscale(0);
                    }
                    path[fill="#FED843"] {
                        fill: #FED843;
                    }
                    path[fill="#FED843"] + path[fill="#FABE2C"] {
                        fill: #FABE2C;
                    }
                }
            }
            &[class~=btn-favourite] {
                &[class~=active] {
                    [stroke="#333333"] {
                        stroke: transparent;
                        fill: var(--gw-brand-color-red-1);
                    }
                }
            }
            &:hover {
                [class~=btn-circle] {
                    box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.50), 0px 20px 40px -4px rgba(140, 158, 173, 0.50);
                }
            }
        }
    }
}

[class~=gw-detail-title] {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: var(--gw-brand-color-red-1);
}

[class~=gw-location] {
    [class~=gw-location-map] {
        position: relative;
        .btn-navigate-action {
            position: absolute;
            right: 5px;
            bottom: 17.25px;
            button {
                width: 57px;
                height: 57px;
                span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 13px;
                }
                svg {
                    width: 18px;
                    height: 18px;
                    path[stroke="#DD1508"] {
                        stroke: #fff;
                    }
                }
            }
        }
    }
}

[class~=gw-list-nearby-place] {
    [class~=gw-item] {
        [class~=gw-item-content] {
            display: flex;
            gap: 16px;
            cursor: pointer;
            [class~=gw-item-image] {
                width: 82px;
                height: 61.5px;
                object-fit: cover;
            }
            [class~=gw-item-detail] {
                [class~=gw-item-title] {
                    span {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 18px;
                        color: var(--gw-brand-color-black-1);
                    }
                }
                [class~=gw-item-location] {
                    margin-top: 4px;
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    span {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        color: var(--gw-brand-color-black-2);
                    }
                }
                [class~=gw-item-distance] {
                    margin-top: 10px;
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    [class~=gw-text-distance] {
                        display: flex;
                        gap: 2px;
                        span {
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            &[class~=gw-text-1] {
                                color: var(--gw-brand-color-black-1);
                            }
                            &[class~=gw-text-2] {
                                color: var(--gw-brand-color-red-1);
                            }
                        }
                    }
                }
            }
        }
    }
}

[class~=gw-information] {
    [class~=gw-list-informaion] {
        [class~=gw-item] {
            display: flex;
            justify-content: space-between;
            [class~=gw-text-left] {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: var(--gw-brand-color-black-2);
                opacity: 0.8;
            }
            [class~=gw-text-right] {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: var(--gw-brand-color-black-2);
            }
        }
    }
}

[class~=gw-more-details] {
    [class~=gw-more-details-content] {
        span {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: var(--gw-brand-color-black-2);
            opacity: 0.8;
        }
    
        [class~=ant-btn-link] {
            padding: 0;
            height: 22px;
    
            span {
                color: var(--gw-brand-color-red-1);
                text-decoration: underline;
            }
        }
    }
    [class~=gw-more-details-user] {
        display: flex;
        justify-content: space-between;
        [class~=gw-user-details] {
            display: flex;
            gap: 16px;
            width: 100%;
            [class~=gw-image-profile] {
                width: 48px;
                height: 48px;
                object-fit: cover;
                border-radius: 50%;
            }
            [class~=gw-user-content] {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 100%;
                [class~=gw-user-fullname] {
                    span {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 23px;
                        color: var(--gw-brand-color-black-1);
                    }
                }
                [class~=gw-verify-identity-card] {
                    height: 22px;
                    width: 128px;
                    border-radius: 6px;
                    background-color: var(--gw-brand-color-orenge-1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px    ;
                    span {
                        font-weight: 700;
                        font-size: 8px;
                        line-height: 20px;
                        color: #ffffff;
                    }
                }
                .gw-list-document {
                    // margin-top: 21px;
                    .gw-item-document {
                        width: 100%;
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        [stroke="#DD1508"] {
                            stroke: var(--gw-brand-color-other-1);
                        }
                        &.gw-item-active {
                            .gw-icon {
                                [stroke="#DD1508"] {
                                    stroke: var(--gw-brand-color-red-1);
                                }
                            }
                        }
                        .gw-icon {
                            width: 32px;
                            height: 32px;
                            box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                        }
                        .gw-label {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;

                            color: #212B36;
                        }
                        .gw-suffix {
                            border: 0;
                            margin-left: auto;
                            display: flex;
                            padding: 0;
                            height: auto;
                            width: auto;
                            min-height: auto;
                            min-width: auto;
                        }
                    }
                }
            }
        }
        [class~=gw-user-action] {
            display: flex;
            align-items: center;
            button {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                &:hover {
                    border-color: #DD1508;
                }
            }
            [class~=btn-chat] {
                svg {
                    path,
                    ellipse,
                    circle {
                        fill: #DD1508;
                    }
                }
            }
        }
    }
}

[class~=ant-tooltip-inner] {
    border-radius: 8px;
    padding: 6px 10px;
}


[class~=gw-modal-sale] {
    [class~=ant-modal-close-x] {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -30px;
    }
    [class~=ant-modal-content] {
        border-radius: 12px;
    }
    .gw-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: var(--gw-brand-color-red-1);
        margin-bottom: 8px;
    }
    .gw-block-detail {
        display: flex;
        gap: 16px;
        margin-bottom: 24px;
        padding-top: 8px;
    }
    img[class~=ant-image-img] {
        width: 109px;
        height: 83px;
        object-fit: cover;
        border-radius: 1.81395px;
        overflow: hidden;
    }
    [class~=gw-detail] {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-top: 8px;
        height: 52px;
    }
    [class~=ant-modal-footer] {
        text-align: center;
        button {
            height: 36px;
            width: 130px;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 400;

            &[class~=ant-btn-default] {
                background: var(--gw-brand-color-white-1);
                border: 1px solid var(--gw-brand-color-gray-2);
                color: var(--gw-brand-color-black-1);
            }
        
            &[class~=ant-btn-primary] {
                border: 0;
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                background-color: var(--gw-brand-color-red-1);
                color: #ffffff;
            }
        }
    }
    .gw-block-container {
        width: 100%;
        --gw-custom-height: 48px;
        [class~=ant-row] {
            margin-top: 8px;
        }
        .gw-form-selector {
            [class~=ant-select-selection-placeholder] {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: var(--gw-brand-color-black-1);
                opacity: 0.80;
            }

            [class~=ant-select-arrow] {
                top: 40%;
                right: 17px;
            }

            [class~=ant-select] {
                [class~=ant-select-selector] {
                    border-radius: 8px;
                    border-color: var(--gw-brand-color-gray-1);
                    height: var(--gw-custom-height);
                    display: flex;
                    align-items: center;

                    [class~=ant-select-selection-search-input] {
                        height: calc(var(--gw-custom-height) - 2px);

                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: var(--gw-brand-color-black-1);
                    }
                }

                &:not([class~=ant-select-disabled]) {

                    &:hover,
                    &:focus,
                    &[class~=ant-select-focused] {
                        [class~=ant-select-selector] {
                            border-color: var(--gw-brand-color-red-1);
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        .gw-form-input {
            [class~=ant-input-group-compact] {
                display: flex;

                [class~=ant-input-number] {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                button {
                    border-radius: 8px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    font-size: 18px;
                    font-weight: 400;
                    height: calc(var(--gw-custom-height));
                    border: 0;
                    background-color: var(--gw-brand-color-red-1);
                    color: #ffffff;
                    padding: 7px;
                }
            }

            [class~=ant-form-item-control-input-content]>[class~=ant-input],
            [class~=ant-input-affix-wrapper],
            [class~=ant-input-number],
            [class~=ant-input-number-affix-wrapper] {
                width: 100%;
                height: var(--gw-custom-height);
                border-radius: 8px;
                overflow: hidden;
                border-color: var(--gw-brand-color-gray-1);
                box-shadow: none;

                [class~=ant-input-number-input] {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    height: calc(var(--gw-custom-height) - 2px);

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.80;
                        /* Firefox */
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.80;
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: var(--gw-brand-color-black-1);
                        opacity: 0.80;
                    }
                }

                &:not([disabled]),
                &:not([class~=ant-input-number-affix-wrapper-disabled]),
                &:not([class~=ant-input-affix-wrapper-disabled]) {

                    &:hover,
                    &:focus,
                    &[class~=ant-input-number-affix-wrapper-focused],
                    &[class~=ant-input-affix-wrapper-focused] {
                        border-color: var(--gw-brand-color-red-1);
                    }
                }

                [class~=ant-input-number-prefix] {
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                [class~=ant-input-suffix] {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--gw-brand-color-red-1);
                }
            }

            &[class~=ant-form-item-has-error] {

                [class~=ant-form-item-control-input-content]>[class~=ant-input],
                [class~=ant-input-affix-wrapper],
                [class~=ant-input-number-affix-wrapper] {
                    border-color: #ff4d4f;

                    &:not([disabled]),
                    &:not([class~=ant-input-number-affix-wrapper-disabled]),
                    &:not([class~=ant-input-affix-wrapper-disabled]) {

                        &:hover,
                        &:focus,
                        &[class~=ant-input-number-affix-wrapper-focused],
                        &[class~=ant-input-affix-wrapper-focused] {
                            border-color: #ff4d4f;
                        }
                    }
                }
            }
        }
    }
    [class~=ant-form-item] {
        margin: 0;
    }
    [class~=ant-modal-footer] {
        padding: 15px 16px;
    }
}

[class~=gw-travel-time] {
    background: #FFFFFF;
    border-radius: 12px;

    display: flex;
    flex-direction: column;

    &:not([class~=gw-travel-time-mobile]) {
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        padding: 17px 24px 17px;
    }

    [class~=ant-divider] {
        margin: 0;
    }

    [class~=gw-travel-time-account] {
        display: flex;
        gap: 8px;

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
        }
        [class~=gw-travel-time-account-info] {
            display: flex;
            flex-direction: column;
            gap: 0px;
            [class~=gw-travel-time-account-info-time] {
                display: flex;
                align-items: end;
                gap: 5px;

                [class~=gw-text-mini] {
                    font-size: 12px;
                }
            }
            [class~=gw-travel-time-edit-btn] {
                font-size: 12px;
                color: #DD1508;
                padding: 0;
                height: 23px;
            }
        }
    }

    [class~=gw-travel-time-list] {
        margin-top: 8px;
        margin-bottom: 8px;
        [class~=gw-travel-time-item] {
            button {
                border-radius: 8px;
                text-align: left;
                color: #B8B7B7;
                span {
                    line-height: 20px;
                }
                &[class~=ant-btn]:focus, 
                &[class~=ant-btn]:hover {
                    color: #DD1508;
                    border-color: #DD1508;
                    background: #fff;
                }
            }
        }
    }
}

.gw-modal-map {
    [class~=ant-modal-body] {
        padding: 24px;
    }
    .gw-input-search {
        width: 303px;
        height: 32px;

        input,
        button {
            height: 32px;
            border: 1px solid #CED4D9 !important;
            box-shadow: none !important;
        }

        &:hover {
            input {
                border-color: var(--gw-brand-color-red-1) !important;
            }

            button {
                background-color: var(--gw-brand-color-red-1);
                border-color: var(--gw-brand-color-red-1) !important;
            }
        }

        input {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-right: 0 !important;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            &:focus {
                border-color: var(--gw-brand-color-red-1) !important;

                &~span button {
                    background-color: var(--gw-brand-color-red-1);
                    border-color: var(--gw-brand-color-red-1) !important;
                }
            }
        }

        button {
            z-index: 1;
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
            transition: all .3s;
            border-left: 0 !important;
            background-color: var(--gw-brand-color-red-1) !important;
            color: var(--gw-brand-color-white-1) !important;
        }
    }
}

[class~=gw-travel-time] {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    [class~=gw-travel-time-account] {
        display: flex;
        gap: 8px;

        div[class~=gw-avatar] {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            object-fit: cover;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(221, 21, 8, 1);
            span {
                padding-bottom: 2px;
                font-size: 20px;
                color: var(--gw-brand-color-white-1);
            }
        }

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
        }

        [class~=gw-travel-time-account-info] {
            display: flex;
            flex-direction: column;
            gap: 0px;

            [class~=gw-travel-time-account-info-time] {
                display: flex;
                align-items: end;
                gap: 5px;

                [class~=gw-text-mini] {
                    font-size: 12px;
                }
            }
        }
    }
}

[class~=ant-modal] {
    [class~=gw-travel-time] {
        box-shadow: none;
        padding: 0;
    }
}