[class~=gw-carousel-item] {
    padding: 12px 11px 15px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 12px;
    position: relative;
    width: 100%;
    transition: box-shadow .3s ease-in-out;

    &[class~=gw-active] {
        box-shadow: rgba(221, 21, 8, 0.7) 0px 0px 0px 3px;
    }

    [class~=gw-item-mask] {
        z-index: 16;
        border-radius: 12px;
    }

    [class~=gw-carousel-item-header] {
        display: flex;
        align-items: center;
        gap: 4px;

        [class~=gw-badge-verified] {
            width: 35px;
            height: 16px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: var(--gw-brand-color-red-1);
            color: #ffffff;
            font-weight: 500;
            font-size: 8px;
            line-height: 20px;
        }

        [class~=gw-card-image-profile] {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }

        [class~=gw-card-user-info] {
            display: flex;
            flex-direction: column;
            gap: 2;

            [class~=gw-card-username] {
                display: flex;
                align-items: center;
                gap: 8px;

                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: var(--gw-brand-color-black-1);
            }

            [class~=gw-card-location] {
                gap: 2px;

                span {
                    color: var(--gw-brand-color-black-1);
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 13px;
                    opacity: .64;
                }

                [class~=ant-breadcrumb] {
                    height: 13px;
                    display: flex;
                }

                [class~=ant-breadcrumb-separator] {
                    margin: 0px 2px;
                }

                [class~=gw-dot] {
                    width: 4px;
                    height: 4px;
                    margin-bottom: 2px;

                    circle {
                        fill: var(--gw-brand-color-gray-1);
                    }
                }
            }
        }
    }

    [class~=gw-card-carousel-images] {
        position: relative;

        [class~=ant-carousel] {
            [class~=slick-list] {
                height: 240px;
            }

            [class~=gw-carousel-item-image] {
                cursor: pointer;
            }

            [class~=ant-image-img] {
                height: 240px;
                border-radius: 8px;
                overflow: hidden;
                object-fit: cover;
            }

            [class~=slick-dots] {
                &[class~=slick-dots-bottom] {
                    bottom: -22px;
                    z-index: 0;
                }

                li {
                    margin: 0 2px;
                    height: 4px;
                    width: 4px;

                    button {
                        border-radius: 4px;
                        background: var(--gw-brand-color-red-1);
                        height: 4px;
                        width: 4px;
                    }

                    &[class~=slick-active] {
                        width: 12px;

                        button {
                            width: 12px;
                        }
                    }
                }
            }
        }

        [class~=gw-card-carousel-images-button] {
            position: absolute;
            right: 8px;
            top: 9px;

            padding: 0px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.16);
            border: 1px solid #F9FBFC;
            border-radius: 4px;
            * {
                transition: all .3s cubic-bezier(.645,.045,.355,1);
            }

            &:hover {
                [fill="#CED4D9"] {
                    fill: var(--color-btn);
                    stroke: unset;
                }
            }
            
            &[class~=active] {
                --color-btn: var(--gw-brand-color-red-1);
                color: var(--color-btn);
                border-color: var(--color-btn);
                background-color: transparent;
                
                [fill="#CED4D9"] {
                    fill: var(--color-btn);
                    stroke: unset;
                }
            }
        }
        // &:hover {
        //     .gw-card-tag {
        //         .gw-card-tag-item {
        //             background-color: #f47f2073;
        //         }
        //     }
        // }
    }

    [class~=gw-card-text-content] {
        margin-top: 16px;

        span {
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            color: var(--gw-brand-color-black-1);
        }

        [class~=ant-btn-link] {
            padding: 0;
            height: 22px;

            span {
                color: var(--gw-brand-color-red-1);
                text-decoration: underline;
            }
        }
    }

    [class~=gw-card-price] {
        --color-gray: #3b3b3bb3;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;

        [class~=gw-card-detail-left] {
            color: var(--color-gray);
            display: flex;
            flex-direction: column;

            [class~=price] {
                display: flex;
                align-items: center;
                gap: 8px;

                [class~=price-unit] {
                    display: flex;
                    gap: 2px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;

                    [class~=unit] {
                        color: var(--gw-brand-color-red-1);
                    }

                    [class~=value] {
                        color: var(--gw-brand-color-red-1);
                    }
                }

                [class~=gw-badge-sale] {
                    margin-left: 8px;

                    padding: 0px 8.5px;
                    height: 24px;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    background-color: var(--gw-brand-color-red-1);
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            [class~=area] {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }

        }

        [class~=gw-card-detail-right] {
            display: flex;
            flex-direction: column;
            gap: 3px;

            [class~=text-icon] {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                gap: 2px;
                align-items: center;
            }

            [class~=text-icon] span {
                color: var(--color-gray);
            }

            [class~=text-icon] svg {
                path {
                    fill: var(--color-gray);
                }
            }
            
            [class~=layer-1] {
                display: flex;
                justify-content: flex-end;
                gap: 8px;

                span {
                    white-space: nowrap;
                }
            }

            [class~=layer-2] {
                display: flex;
                justify-content: flex-end;

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    [class~=gw-card-footer] {
        margin-top: 9px;
        display: flex;
        justify-content: space-between;

        [class~=gw-card-footer-left] {
            display: flex;
            gap: 8px;

            >div {
                width: 16px;
                height: 16px;

                >svg {
                    width: 16px;
                    height: 16px;
                }
            }

            [class~=btn-coin] {
                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }

                [class~=ant-image] {
                    display: flex;
                }
                img {
                    width: 17px;
                    height: 17px;
                }

                svg * {
                    filter: none;
                    -webkit-filter: grayscale(1);
                }

                path[fill="#FED843"],
                path[fill="#FED843"]+path[fill="#FABE2C"] {
                    fill: #ffffff;
                }

                &[class~=active] {
                    svg * {
                        filter: none;
                        -webkit-filter: grayscale(0);
                    }

                    path[fill="#FED843"] {
                        fill: #FED843;
                    }

                    path[fill="#FED843"]+path[fill="#FABE2C"] {
                        fill: #FABE2C;
                    }
                }

                &:hover,
                &:focus {
                    svg * {
                        filter: none;
                        -webkit-filter: grayscale(0.45);
                    }

                    path[fill="#FED843"] {
                        fill: #FED843;
                    }

                    path[fill="#FED843"]+path[fill="#FABE2C"] {
                        fill: #FABE2C;
                    }
                }
            }

            [class~=btn-chat] {
                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }

                &:hover {
                    [fill="#333333"] {
                        fill: #2196f3;
                    }
                }
            }

            [class~=btn-share] {
                * {
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);
                }

                &:hover {
                    [fill="#333333"] {
                        fill: var(--gw-brand-color-red-1);
                    }
                }
            }
        }

        [class~=gw-card-footer-right] {
            display: flex;
            gap: 8px;

            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: var(--gw-brand-color-black-2);
                opacity: 0.64;
            }
        }
    }
}

.gw-card-tag {
    position: absolute;
    bottom: 12px;
    left: 12px;
    z-index: 1;
    display: flex;
    gap: 5px;

    .gw-card-tag-item {
        transition: background-color .3s ease-in-out;
        color: #fff;
        border-radius: 6px;
        padding: 0px 10px;

        span {
            line-height: 20px;
            font-size: 12px;
        }
        
        &.gw-type {
            background-color: #E93F40B3;
            &[type="rent"] {
                background-color: #EF650CB3;
            }
        }
        &.gw-category {
            &[category="1"] {
                background-color: rgba(16, 115, 110, 0.70);
                background-color: #7FC379B3;
            }
            &[category="2"] {
                background-color: rgba(252, 217, 40, 0.70);
                background-color: #FADC2AB3;
            }
            &[category="3"] {
                background-color: rgba(113, 200, 235, 0.70);
                background-color: #9FDCF3B3;
            }
            &[category="4"] {
                background-color: rgba(89, 89, 92, 0.70);
                background-color: #9D9D9FB3;
            }
            &[category="5"] {
                background-color: rgba(116, 57, 53, 0.70);
                background-color: #936941B3;
            }
            &[category="6"] {
                background-color: rgba(241, 88, 226, 0.70);
                background-color: #FF68D5B3;
            }
            &[category="7"] {
                background-color: rgba(113, 200, 235, 0.70);
                background-color: #9FDCF3B3;
            }
        }
    }
}