.gw-modal-block-content {
    overflow: hidden;
    min-height: 310px;
    .gw-modal-content {
        position: relative;
        width: 100%;
        // padding: 0 24px;
        height: 334px;
        overflow: hidden;
        .background {
            position: absolute;
            user-select: none;
            left: -1px;
            top: -123px;
            width: 101%;
        }
        .detail {
            position: absolute;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            align-items: center;
            .logo {
                margin-top: 105px;
                max-width: 185px;
            }
            .text {
                margin-top: 8px;
                font-size: 18px;
                color: #ffffff;
            }
        }
    }
    .gw-modal-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 24px;
        .text-login {
            margin-top: 25px;
            color: var(--gw-brand-color-red-1);
            font-size: 24px;
            font-weight: 500;
        }

        .text-please-login {
            margin-top: 8px;
            color: var(--gw-brand-color-black-1);
            font-size: 18px;
            font-weight: 400;
        }

        .btn-skip-login {
            margin-top: 24px;
            .text-skip-login {
                color: var(--gw-brand-color-red-1);
                font-size: 16px;
                font-weight: 400;
                text-decoration: underline;
            }
        }

        .list-btn-social {
            margin-top: 24px;
            display: flex;
            gap: 16px;

            .btn-text-box {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-size: 18px;
                font-weight: 400;

                img {
                    width: 20px;
                    height: 20px;
                }
            }

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                width: 182.33px;
                height: 45px;
                border-radius: 8px;
                box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.24);
                &:hover {
                    box-shadow: 0px 10px 17px rgba(51, 51, 51, 0.45);
                }
            }

            .btn-facebook {
                background-color: #4267B2;
                color: white;
            }

            .btn-google {
                background-color: var(--gw-brand-color-white-1);
                color: var(--gw-brand-color-black-1);
            }

            .btn-apple {
                background-color: var(--gw-brand-color-black-1);
                color: white;
            }
        }
    }
}