.navigate-container {
    position: relative;
    min-height: calc(100vh - 80px - 32px);
    .btn-navigate-action {
        position: fixed;
        z-index: 2;
        right: 16px;
        bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        button {
            background-color: var(--gw-brand-color-red-1);
            color: white;
            width: 64px;
            height: 64px;
            box-shadow: 0px 8px 16px rgba(28, 143, 108, 0.24);
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
                font-size: 12px;
                font-weight: 400;
            }
            img {
                height: 24px;
            }
            &:hover,
            &:focus {
                box-shadow: 0px 19px 37px rgba(28, 143, 108, 0.35);
            }
        }

        @media screen and (max-width: 767px) {
            bottom: 80px;
        }
    }
}

.gw-side-menu {
    .gw-header {
        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: var(--gw-brand-color-red-1);
        }
    }

    .gw-list-menu {
        margin-top: 45px;

        [class~=ant-btn] {
            margin-bottom: 16px;
            width: 100%;
            height: 80px;
            border: none;
            padding: 24px;
            border-radius: 12px;
            box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
            background-color: #EFF0F0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            span {
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;

                color: var(--gw-brand-color-black-1);
                opacity: 0.72;
            }
            &[disabled] {
                cursor: default;
                background-color: #ffffff;
                span {
                    color: var(--gw-brand-color-red-1);
                    opacity: 1;
                }
            }
            &:not([disabled]):focus,
            &:not([disabled]):hover {
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.60);
            }
            .gw-btn-icon {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                svg {
                    [fill="#DD1508"] {
                        fill: #333333;
                        fill-opacity: 0.72;
                    }
                }
            }
            &[disabled] {
                .gw-btn-icon {
                    
                    border-radius: 50%;
                    box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                    svg {
                        [fill="#333333"] {
                            fill: #DD1508;
                            fill-opacity: 0.72;
                        }
                    }
                }
            }
        }

        &.gw-list-menu-active {
            [class~=ant-btn] {
                background-color: #ffffff;
                span {
                    color: var(--gw-brand-color-red-1);
                    opacity: 1;
                }
                .gw-btn-icon {
                    
                    border-radius: 50%;
                    box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                    svg {
                        [fill="#333333"] {
                            fill: #DD1508;
                            fill-opacity: 0.72;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }
}

.gw-feed-content {
    .gw-feed-list {
        padding-bottom: 24px;
    }
}

.gw-mobile-navigation {
    position: fixed;
    top: 70px;
    right: 0px;
    z-index: 10;
    background-color: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}