.gw-container {
    width: 100%;
    max-width: var(--gw-container-w);
    margin: 0 auto;
    padding: 0 5px;
}

.gw-modal-container {
    max-width: 627px;

    .ant-modal-content {
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        border-radius: 12px;
        overflow: hidden;
    }

    .ant-modal-body {
        padding: 0;
        position: relative;
        width: 100%;
        min-height: 50px;
    }

    .gw-modal-block-close {
        position: absolute;
        z-index: 2;
        right: 8px;
        top: 8px;

        .gw-modal-close {
            display: flex;
            cursor: pointer;

            * {
                transition: all .3s cubic-bezier(.645, .045, .355, 1);
            }

            >svg {
                cursor: pointer;
                margin-left: auto;

                &:hover {
                    circle[fill="#CED4D9"] {
                        fill: var(--gw-brand-color-red-1);
                    }
                }
            }
        }
    }
}

.gw-d-flex {
    display: flex;
}

.gw-d-flex-column {
    flex-direction: column;
}

.gw-w-100 {
    width: 100%;
}

.gw-mt-8 {
    margin-top: 8px;
}

.gw-mt-16 {
    margin-top: 16px;
}

.gw-mt-24 {
    margin-top: 24px !important;
}

.gw-mt-32 {
    margin-top: 32px;
}

.gw-mt-40 {
    margin-top: 42px;
}

.gw-mt-42 {
    margin-top: 42px;
}

.gw-mt-48 {
    margin-top: 48px;
}

.gw-btn-link {
    cursor: pointer;
    display: flex;
    align-items: center;

    &.gw-btn-danger {
        span {
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: var(--gw-brand-color-red-1);
        }
    }
}

.gw-cursor-pointer {
    cursor: pointer;
}

.ant-divider {
    border-color: var(--gw-brand-color-gray-1);

    &.ant-divider-horizontal {
        margin: 16px 0px;
    }
}

[class~=ant-back-top] {
    right: 50px;
    bottom: 90px;
    opacity: 65%;
    transition: all .3s;

    [class~=btn-back-to-top] {
        background-color: var(--gw-brand-color-red-1);
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        [class~=anticon] {
            transition: all .3s;
            color: white;
            font-size: 18px;
            transform: translateY(-1px);
        }
    }

    &:hover {
        opacity: 100%;

        [class~=btn-back-to-top] {
            [class~=anticon] {
                transform: translateY(-5px);
            }
        }
    }
}

// .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
// .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper).ant-input-number,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper).ant-input-number:hover {
    background-color: #fff;
    border-color: #ff4d4f;
}

@media (max-width: 767px) {
    .gw-container {
        max-width: 100%;
        padding: 0 15px;
    }

    main {
        overflow: hidden;
    }

    main .gw-container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    main .gw-container.gw-mt-48 {
        padding-top: 35px;
    }

    .ant-modal-body {
        padding: 0px;
    }

    .ant-modal-body .ant-row-center .ant-row {
        flex-flow: nowrap;
    }
}


.editor-container {
    background-color: #FF0606;
    max-width: 100%;
    color: #fff;
    position: relative;
    line-height: 43px;
    font-weight: 400;
    text-align: center;

    &.vertical-align {
        display: flex;
        align-items: center; /* Align items vertically center */
        justify-content: center; /* Align items horizontally center */
        min-height: 300px; /* Example height for the container */
    }

    &[category],
    &[category]:hover {
        background-color: #FF0606;
    }

    &[category="1"],
    &[category="1"]:hover {
        background-color: #FF0606;
        // background-color: rgba(16, 115, 110, 1);
    }

    &[category="2"],
    &[category="2"]:hover {
        background-color: rgba(252, 217, 40, 1);
    }

    &[category="3"],
    &[category="3"]:hover {
        background-color: rgba(113, 200, 235, 1);
    }

    &[category="4"],
    &[category="4"]:hover {
        background-color: rgba(89, 89, 92, 1);
    }

    &[category="5"],
    &[category="5"]:hover {
        background-color: rgba(116, 57, 53, 1);
    }

    &[category="6"],
    &[category="6"]:hover {
        background-color: rgba(241, 88, 226, 1);
    }

    &[category="7"],
    &[category="7"]:hover {
        background-color: rgba(113, 200, 235, 1);
    }
}

.editor-input {
    min-height: 100%;
    width: 100%;
    resize: none;
    font-size: 36px;
    caret-color: rgb(5, 5, 5);
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    caret-color: #444;
}

.editor-placeholder {
    color: #eeeeee;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 121px;
    font-size: 36px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
    margin: auto;
    left: 0;
    width: 100%;
}

.editor-paragraph {
    margin: 0 0 15px 0;
    position: relative;
}

.tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    line-height: 14px;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}

.gw-mini-card {
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    
    &:hover {
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.50);
    }

    .gw-add {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
    }

    .gw-text-price {
        font-family: Sarabun;
        font-size: 9px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000000;
        // display: flex;
        // align-items: flex-end;
        padding: 5px 8px;
        // line-height: 12px;
        // height: 34px;
        text-align: left;
        
        line-height: 8px;
        height: 28px;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
    }
    .gw-avatar {
        position: absolute;
        z-index: 1;
        top: 3px;
        left: 3px;
        border-radius: 50%;
        overflow: hidden;
    }
    .editor-container {
        line-height: 16px;
    }
    .editor-container.vertical-align {
        min-height: 125px;
    }
    .editor-paragraph {
        margin: 0;
    }
    .editor-input {
        font-size: 12px;
        padding: 5px;
        padding-top: 23px;
    }
}
.gw-textarea-block,
.gw-textarea-card,
.gw-textarea-card[class~=ant-input] {
    font-size: 40px !important;
    line-height: 52px !important;
    // min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #FF0606 !important;
    max-width: 100%;
    color: #fff !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    border-radius: 0 !important;
    border: 0 !important;

    &[readonly] {
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // 620
        max-height: 219px  !important;
        min-height: 75px;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    &[category],
    &[category]:hover,
    &[category][broadcasttypeid="3"],
    &[category][broadcasttypeid="3"]:hover {
        background-color: #FF0606 !important;
    }

    &[category="1"],
    &[category="1"]:hover {
        background-color: #FF0606 !important;
    }

    &[category="2"],
    &[category="2"]:hover {
        background-color: rgba(252, 217, 40, 1) !important;
    }

    &[category="3"],
    &[category="3"]:hover {
        background-color: rgba(113, 200, 235, 1) !important;
    }

    &[category="4"],
    &[category="4"]:hover {
        background-color: rgba(89, 89, 92, 1) !important;
    }

    &[category="5"],
    &[category="5"]:hover {
        background-color: rgba(116, 57, 53, 1) !important;
    }

    &[category="6"],
    &[category="6"]:hover {
        background-color: rgba(241, 88, 226, 1) !important;
    }

    &[category="7"],
    &[category="7"]:hover {
        background-color: rgba(113, 200, 235, 1) !important;
    }
}

.gw-textarea-block {
    height: 350px;
    padding: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.gw-mini-card {
    .gw-textarea-block {
        height: 125px;
        padding: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .gw-textarea-card,
    .gw-textarea-card[class~=ant-input] {
        font-size: 12px !important;
        line-height: 16px !important;
        padding: 5px !important;
        padding-top: 23px !important;
        // height: 120px !important;
        overflow: hidden;
            
        &[readonly] {
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            // 620
            max-height: 57px  !important;
            min-height: 10px;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }
    }
}