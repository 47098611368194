.gw-header {
    display: flex;
    align-items: center;
    gap: 8px;
    .gw-text-prefix {
        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;

            color: var(--gw-brand-color-red-1);
        }
    }
}
.gw-chat-header-search {
    display: flex;
    align-items: center;

    [class~=ant-input-affix-wrapper] {
        padding: 0px 8px;
        height: 40px;
        width: 344px;
        border-radius: 8px;
        border: 1px solid #CED4D9;
        box-shadow: none;

        &[class~=ant-input-affix-wrapper-focused],
        &[class~=ant-input-affix-wrapper]:focus {
            border-color: var(--gw-brand-color-red-1);
        }

        input {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: var(--gw-brand-color-black-1);

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: var(--gw-brand-color-gray-1);
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: var(--gw-brand-color-gray-1);
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: var(--gw-brand-color-gray-1);
            }
        }
    }
}