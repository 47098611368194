.gw-modal-block-content {
    overflow: hidden;
    min-height: 310px;
    .gw-modal-content {
        position: relative;
        width: 100%;
        height: 510px;
        overflow: hidden;

        .gw-modal-carousel {
            .gw-modal-item {
                height: 382px;
                color: #fff;
                background: #ffffff;

                [gw-class="text-content"] {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 100%;
                    justify-content: flex-end;
                    padding-bottom: 70px;
                    [gw-class="label-title"] {
                        font-size: 32px;
                        font-weight: 500;
                        color: var(--gw-brand-color-red-1);
                    }
                    
                    [gw-class="label-description"] {
                        font-size: 18px;
                        font-weight: 400;
                        color: var(--gw-brand-color-black-1);
                    }
                }
            }
        }
        
        [class~=ant-carousel] [class~=slick-dots] li button {
            margin: auto;
            height: 8px;
            width: 8px;
            border-radius: 4px;
            background-color: var(--gw-brand-color-red-1);
        }

        [class~=ant-carousel] [class~=slick-dots] li[class~=slick-active] button {
            height: 8px;
            width: 18px;
            border-radius: 4px;
            background-color: var(--gw-brand-color-red-1);
        }

        .gw-modal-carousel-action {
            gap: 24px;
            display: flex;
            justify-content: center;
            margin-top: 40px;

            button {
                height: 40px;
                width: 194px;
                border-radius: 8px;
                font-size: 18px;
                font-weight: 400;
                > div {
                    display: flex;
                    justify-content: center;
                    gap: 8px;
                }
            }

            .gw-btn-previous {
                background: var(--gw-brand-color-gray-2);
                border: 1px solid var(--gw-brand-color-gray-1);
                color: var(--gw-brand-color-black-1);
            }

            .gw-btn-next {
                border: 0;
                box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
                background-color: var(--gw-brand-color-red-1);
                color: #ffffff;
            }
        }
    }
}