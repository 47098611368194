.gw-modal-block-content {
    overflow: hidden;
    // min-height: 310px;
    padding: 24px;
    .gw-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        color: var(--gw-brand-color-red-1);
    }

    .gw-detail {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
    }

    .gw-share {
        display: flex;
        gap: 8px;
        margin-top: 8px;
        margin-bottom: 16px;
        svg {
            width: 32px;
            height: 32px;
        }
    }
}