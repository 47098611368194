.gw-header {
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
    border-radius: 12px;

    .gw-status {
        margin-top: 9px;
        display: flex;
        gap: 23.5px;
        justify-content: flex-end;
        .gw-group-text {
            display: flex;
            flex-direction: column;
            span.gw-text-count {
                font-weight: 500;
                font-size: 32px;
                line-height: 42px;

                color: var(--gw-brand-color-red-1);
            }
            span.gw-text-detail {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;

                color: var(--gw-brand-color-black-1);
            }
        }
        
        @media screen and (max-width: 767px) {
            justify-content: space-around;
        }
    }
    .gw-description {
        display: flex;
        flex-direction: column;
        span.gw-text-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;

            color: var(--gw-brand-color-red-1);
        }
        span.gw-text-detail {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;

            color: var(--gw-brand-color-black-2);
            opacity: 0.8;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 4px 0px;
        }
    }
    .gw-list-document {
        margin-top: 21px;
        .gw-item-document {
            width: 100%;
            display: flex;
            gap: 8px;
            align-items: center;
            [stroke="#DD1508"] {
                stroke: var(--gw-brand-color-other-1);
            }
            &.gw-item-active {
                .gw-icon {
                    [stroke="#DD1508"] {
                        stroke: var(--gw-brand-color-red-1);
                    }
                }
            }
            .gw-icon {
                width: 32px;
                height: 32px;
                box-shadow: 0px 0px 2px rgba(140, 158, 173, 0.24), 0px 20px 40px -4px rgba(140, 158, 173, 0.24);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
            .gw-label {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;

                color: #212B36;
            }
            .gw-suffix {
                border: 0;
                margin-left: auto;
                display: flex;
                padding: 0;
                height: auto;
                width: auto;
                min-height: auto;
                min-width: auto;
            }
        }
    }
    .gw-btn-edit {
        width: 292px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background-color: var(--gw-brand-color-red-1);
        color: #ffffff;
        box-shadow: 0px 8px 16px rgba(206, 212, 217, 0.24);
        border-radius: 8px;
    }
}

.gw-text-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    color: var(--gw-brand-color-red-1);
}